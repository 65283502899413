<template>
  <!-- 查看详情区域 -->
  <div>
    <div class="scrollContainer">
      <div v-if="buttonActive == 1">
        <div>
          <div class="inductionTitleLab inductionTitleMsg">基本信息</div>
          <el-form class="filter-form rpm-query-from">
            <div class="flex flexBetw AlCenter">
              <div class="flex-1">
                <div class="flex flexBetw AlCenter flex-1 rowItemindex1">
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Number</div>
                    <div class="lableContent">{{ selectEditMsg.number }}</div>
                  </div>
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Country</div>
                    <div class="lableContent">
                      {{ selectEditMsg.countryName }}
                    </div>
                  </div>
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Labor Code</div>
                    <el-tooltip
                      :content="selectEditMsg.laborCodeId"
                      placement="top"
                    >
                      <div class="lableContent">
                        {{ selectEditMsg.laborCodeId }}
                      </div>
                    </el-tooltip>
                  </div>
                </div>
                <div class="flex flexBetw AlCenter flex-1 rowItem">
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Assigment/Project (Level2)</div>
                    <div class="lableContent">
                      {{ selectEditMsg.assigmentProjectLevel2Name }}
                    </div>
                  </div>

                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Description (Level3)</div>
                    <el-tooltip
                      :content="selectEditMsg.descriptionLevel3Name"
                      placement="top"
                    >
                      <div class="lableContent">
                        {{ selectEditMsg.descriptionLevel3Name }}
                      </div>
                    </el-tooltip>
                  </div>



                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Client</div>
                    <div class="lableContent">{{ selectEditMsg.client }}</div>
                  </div>
                </div>
                <div class="flex flexBetw AlCenter flex-1 rowItem">
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">HFM Code</div>
                    <div class="lableContent">
                      {{ selectEditMsg.hfmCode }}
                    </div>
                  </div>

                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Business Unit Name</div>
                    <div class="lableContent">
                      {{ selectEditMsg.businessUnitName }}
                    </div>
                  </div>

                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Department</div>
                    <div class="lableContent">
                      {{ selectEditMsg.departmentName }}
                    </div>
                  </div>
                </div>
                <div class="flex flexBetw AlCenter flex-1 rowItem">
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Work Location</div>
                    <div class="lableContent">
                      {{ selectEditMsg.workingLocationId }}
                    </div>
                  </div>
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Job Title</div>
                    <div class="lableContent">{{ selectEditMsg.jobTitle }}</div>
                  </div>
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Estimated Start Date</div>
                    <div class="lableContent">
                      {{ selectEditMsg.estimatedStartDate }}
                    </div>
                  </div>
                </div>
                <div class="flex flexBetw AlCenter flex-1 rowItem">
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">
                      Internal candidate or new hire
                    </div>
                    <div class="lableContent">
                      {{ selectEditMsg.internalCandidateOrNewHireId }}
                    </div>
                  </div>
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">New Candidate Name</div>
                    <div class="lableContent">
                      {{ selectEditMsg.newCandidateName }}
                    </div>
                  </div>
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Legal Entity</div>
                    <div class="lableContent">
                      {{ selectEditMsg.legalEntity }}
                    </div>
                  </div>
                </div>
                <div class="flex flexBetw AlCenter flex-1 rowItem">
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">CEO</div>
                    <div class="lableContent">
                      {{ selectEditMsg.ceoName }}
                    </div>
                  </div>
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">CEO-1</div>
                    <div class="lableContent">
                      {{ selectEditMsg.ceoName1 }}
                    </div>
                  </div>
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Lion ID of LM</div>
                    <div class="lableContent">
                      {{ selectEditMsg.lineManagerLiid }}
                    </div>
                  </div>

                  <!-- <div class="flex-1 flexRow">
                    <div class="lableDetail">LM Email</div>
                    <div class="lableContent">
                      {{ selectEditMsg.lineManagerEmail }}
                    </div>
                  </div> -->
                </div>

                <div class="flex flexBetw AlCenter flex-1 rowItem">
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Name of LM</div>
                    <div class="lableContent">
                      {{ selectEditMsg.lineManagerName }}
                    </div>
                  </div>
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">
                      Open less than 90 Days or Freelance Utillized
                    </div>
                    <div class="lableContent lableContentSmall">
                      {{ selectEditMsg.openLessThan90daysOrFreelanceUtillized }}
                    </div>
                  </div>
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">In Final Recruitment Stage</div>
                    <div class="lableContent">
                      {{ selectEditMsg.inFinalRecruitmentStage }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-form>
        </div>
        <div>
          <div class="inductionTitleLab inductionTitleMsg">工作制&薪水</div>
          <el-form class="filter-form rpm-query-from">
            <div class="flex-1">
              <div class="flex flexBetw AlCenter flex-1 rowItemindex1">
                <div class="flex-1 flexRow">
                  <div class="lableDetail">Work Schedules</div>
                  <div class="lableContent">
                    {{ selectEditMsg.workSchedulesName }}
                  </div>
                </div>
                <div class="flex-1 flexRow">
                  <div class="lableDetail">Base Salary (Local Currency)</div>
                  <div class="lableContent">{{ selectEditMsg.baseSalary }}</div>
                </div>
                <div class="flex-1 flexRow">
                  <div class="lableDetail">是否十三薪</div>
                  <div class="lableContent">
                    {{ selectEditMsg.offered13thSalary }}
                  </div>
                </div>
              </div>
              <div class="flex flexBetw AlCenter flex-1 rowItem">
                <div class="flex-1 flexRow">
                  <div class="lableDetail">Benefits (Local Currency)</div>
                  <div class="lableContent">{{ selectEditMsg.benefits }}</div>
                </div>
                <div class="flex-1 flexRow">
                  <div class="lableDetail">Relocation Cost</div>
                  <div class="lableContent">
                    {{ selectEditMsg.relocationCost }}
                  </div>
                </div>
                <div class="flex-1 flexRow">
                  <div class="lableDetail">
                    Annual Total Cost (Local Currency)
                  </div>
                  <div class="lableContent">
                    {{ selectEditMsg.annualTotalCost }}
                  </div>
                </div>
              </div>
              <div class="flex flexBetw AlCenter flex-1 rowItem">
                <div class="flex-1 flexRow">
                  <div class="lableDetail">Euro Conv</div>
                  <div class="lableContent">{{ selectEditMsg.euroConv }}</div>
                </div>
                <div class="flex-1 flexRow">
                  <div class="lableDetail">Annual Compensation（euros）</div>
                  <div class="lableContent">
                    {{ selectEditMsg.annualCompensation }}
                  </div>
                </div>
                <div class="flex-1 flexRow">
                  <div class="lableDetail">100% Billable</div>
                  <div class="lableContent">
                    {{ selectEditMsg.bill100Percent }}
                  </div>
                </div>
              </div>
              <div class="flex flexBetw AlCenter flex-1 rowItem">
                <div class="flex-1 flexRow">
                  <div class="lableDetail">Budget within latest RF(Y/N)</div>
                  <div class="lableContent">
                    {{ selectEditMsg.budgetWithinLastRf }}
                  </div>
                </div>
                <div class="flex-1 flexRow">
                  <div class="lableDetail">Hire Does Not Increase Capacity</div>
                  <div class="lableContent">
                    {{ selectEditMsg.hireDoesNotIncreaseCapacity }}
                  </div>
                </div>
                <div class="flex-1 flexRow"></div>
              </div>
            </div>
          </el-form>
        </div>
      </div>
      <div v-else>
        <div>
          <div class="inductionTitleLab inductionTitleMsg">基本信息</div>
          <el-form class="filter-form rpm-query-from">
            <div class="flex flexBetw AlCenter">
              <div class="flex-1">
                <div class="flex flexBetw AlCenter flex-1 rowItemindex1">
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Candidate Name</div>
                    <div class="lableContent">
                      {{ selectEditMsg.candidateName }}
                    </div>
                  </div>
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Candidate English Name</div>
                    <div class="lableContent">
                      {{ selectEditMsg.candidateEnglishName }}
                    </div>
                  </div>
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Candidate Phone</div>
                    <div class="lableContent">
                      {{ selectEditMsg.candidatePhone }}
                    </div>
                  </div>
                </div>
                <div class="flex flexBetw AlCenter flex-1 rowItem">
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Candidate Email</div>
                    <div class="lableContent">
                      {{ selectEditMsg.candidateEmail }}
                    </div>
                  </div>
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Country</div>
                    <div class="lableContent">
                      {{ selectEditMsg.countryName }}
                    </div>
                  </div>
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Expat</div>
                    <div class="lableContent">
                      {{ selectEditMsg.expatLocal }}
                    </div>
                  </div>
                </div>
                <div class="flex flexBetw AlCenter flex-1 rowItem">
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Proposed Onboard Date</div>
                    <div
                      class="lableContent flexRow AlCenter"
                      @click="editJoinDate()"
                    >
                      {{ selectEditMsg.proposedOnboardDate }}
                      <img
                        src="@/assets/img/edit.png"
                        class="iconImg"
                        alt=""
                        v-if="timeStatus"
                      />
                    </div>
                  </div>
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">HRBP</div>
                    <div class="lableContent">
                      {{ selectEditMsg.hrbpName }}
                    </div>
                  </div>
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Team</div>
                    <div class="lableContent">
                      {{ selectEditMsg.team }}
                    </div>
                  </div>
                </div>
                <div class="flex flexBetw AlCenter flex-1 rowItem">
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Job Grading</div>
                    <div class="lableContent">
                      {{ selectEditMsg.jobGradingName }}
                    </div>
                  </div>
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Working Location</div>
                    <div class="lableContent">
                      {{ selectEditMsg.workingLocation }}
                    </div>
                  </div>
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Office Location</div>
                    <div class="lableContent">
                      {{ selectEditMsg.officeLocation }}
                    </div>
                  </div>
                </div>
                <div class="flex flexBetw AlCenter flex-1 rowItem">
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">ID Type</div>
                    <div class="lableContent">
                      {{ selectEditMsg.idType }}
                    </div>
                  </div>
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">ID Number</div>
                    <div class="lableContent">
                      {{ selectEditMsg.idNumber }}
                    </div>
                  </div>
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Rejoin Employee</div>
                    <div class="lableContent">
                      {{ selectEditMsg.rejoinEmployee }}
                    </div>
                  </div>
                </div>
                <div class="flex flexBetw AlCenter flex-1 rowItem">
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Fresh/Non-Fresh Graduate</div>
                    <div class="lableContent">
                      {{ selectEditMsg.freshGraduate }}
                    </div>
                  </div>
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Lion ID of LM</div>
                    <div class="lableContent">
                      {{ selectEditMsg.lineManagerLiid }}
                    </div>
                  </div>
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Name of LM</div>
                    <div class="lableContent">
                      {{ selectEditMsg.lineManagerName }}
                    </div>
                  </div>

                </div>
                <div class="flex flexBetw AlCenter flex-1 rowItem">
                  <!-- <div class="flex-1 flexRow">
                    <div class="lableDetail">LM Email</div>
                    <div class="lableContent">
                      {{ selectEditMsg.lineManagerEmail }}
                    </div>
                  </div> -->
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Recruitment Channel</div>
                    <div class="lableContent">
                      {{ selectEditMsg.recruitmentChannelName }}
                    </div>
                  </div>
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Recruitment Channel Remark</div>
                    <div class="lableContent">
                      {{ selectEditMsg.recruitmentChannelRemark }}
                    </div>
                  </div>
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Candidate's Previous Company Category</div>
                    <div class="lableContent">
                      {{ selectEditMsg.candidatePreviousCompanyCategory }}
                    </div>
                  </div>
                </div>

                <div class="flex flexBetw AlCenter flex-1 rowItem">
                  <div class="flex-1 flexRow">
                    <div class="lableDetail">Candidate's Previous Company</div>
                    <div class="lableContent">
                      {{ selectEditMsg.candidatePreviousCompany }}
                    </div>
                  </div>
                  <div class="flex-1 flexRow"></div>
                  <div class="flex-1 flexRow"></div>
                </div>

              </div>
            </div>
          </el-form>
        </div>
        <div>
          <div class="inductionTitleLab inductionTitleMsg">薪资和年假</div>
          <el-form class="filter-form rpm-query-from">
            <div class="flex-1" v-if="selectEditMsg.expatLocal == 'Expat'">
              <div class="flex flexBetw AlCenter flex-1 rowItemindex1">
                <div class="flex-1 flexRow">
                  <div class="lableDetail">
                    Annual Package（Expat）
                  </div>
                  <div class="lableContent">
                    {{ selectEditMsg.annualPackage }}
                  </div>
                </div>
                <div class="flex-1 flexRow"></div>
                <div class="flex-1 flexRow"></div>
              </div>
            </div>
            <div class="flex-1">
              <div class="flex flexBetw AlCenter flex-1 rowItemindex1">
                <div class="flex-1 flexRow">
                  <div class="lableDetail">
                    Offered Monthly Salary (during {{probation}})
                  </div>
                  <div class="lableContent">
                    {{ selectEditMsg.offeredMonthlySalaryDuringProbtion }}
                  </div>
                </div>
                <div class="flex-1 flexRow">
                  <div class="lableDetail">
                    Offered Monthly Salary (after {{probation}})
                  </div>
                  <div class="lableContent">
                    {{ selectEditMsg.offeredMonthlySalaryAfterProbtion }}
                  </div>
                </div>
                <div class="flex-1 flexRow">
                  <div class="lableDetail">Annual Leave Days</div>
                  <div class="lableContent">
                    {{ selectEditMsg.annualLeaveDays }}
                  </div>
                </div>
              </div>
              <div class="flex flexBetw AlCenter flex-1 rowItem">
                <div class="flex-1 flexRow">
                  <div class="lableDetail">Work Schedules</div>
                  <div class="lableContent">
                    {{ selectEditMsg.workSchedulesName }}
                  </div>
                </div>
                <div class="flex-1 flexRow">
                  <div class="lableDetail">Offered 13th Salary</div>
                  <div class="lableContent">
                    {{ selectEditMsg.offered13thSalary }}
                  </div>
                </div>
                <div class="flex-1 flexRow">
                  <div class="lableDetail">Monthly Total Allowance</div>
                  <div class="lableContent">
                    {{ selectEditMsg.monthlyTotalAllowance }}
                  </div>
                </div>
              </div>
              <div class="flex flexBetw AlCenter flex-1 rowItem">
                <div class="flex-1 flexRow">
                  <div class="lableDetail">Bonus Eligible (Y/N)</div>
                  <div class="lableContent">
                    {{ selectEditMsg.bonusEligible }}
                  </div>
                </div>
                <div class="flex-1 flexRow">
                  <div class="lableDetail">Currency</div>
                  <div class="lableContent">
                    {{ selectEditMsg.currency }}
                  </div>
                </div>
                <div class="flex-1 flexRow">
                  <div class="lableDetail">Discretionary Bonus Plan</div>
                  <div class="lableContent">
                    {{ selectEditMsg.discretionaryBonusPlan }}
                  </div>
                </div>
              </div>
              <div class="flex flexBetw AlCenter flex-1 rowItem">
                <div class="flex-1 flexRow">
                  <div class="lableDetail">Discretionary Target Bonus %</div>
                  <div class="lableContent">
                    {{ selectEditMsg.discretionaryTargetBonus }}
                  </div>
                </div>
                <div class="flex-1 flexRow">
                  <div class="lableDetail">Pay Frequency</div>
                  <div class="lableContent">
                    {{ selectEditMsg.payFrequency }}
                  </div>
                </div>
                <div class="flex-1 flexRow">
                  <div class="lableDetail">Special Pool Bonus Plan</div>
                  <div class="lableContent">
                    {{ selectEditMsg.specialPoolBonusPlan }}
                  </div>
                </div>
              </div>
            </div>
          </el-form>
        </div>
      </div>
      <div>
        <div class="inductionTitleLab inductionTitleMsg">合同信息</div>
        <el-form class="filter-form rpm-query-from">
          <div class="flex-1">
            <div class="flex flexBetw AlCenter flex-1 rowItemindex1">
              <div class="flex-1 flexRow">
                <div class="lableDetail">Contract Period</div>
                <div class="lableContent">
                  {{ selectEditMsg.contractPeriodName }}
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">Notice Period</div>
                <div class="lableContent">
                  {{ selectEditMsg.noticePeriodName }}
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">{{upCase(probation)}} Period(Monthly)</div>
                <div class="lableContent">
                  {{ selectEditMsg.probationPeriodMonthly }}
                </div>
              </div>
            </div>
            <div class="flex flexBetw AlCenter flex-1 rowItem">
              <div class="flex-1 flexRow">
                <div class="lableDetail">Notice Period (within {{probation}})</div>
                <div class="lableContent">
                  {{ selectEditMsg.noticePeriodWithinProbation }}
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">Title CN(during {{probation}})</div>
                <div class="lableContent">
                  {{ selectEditMsg.titleCnDuringProbation }}
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">Title EN(during {{probation}})</div>
                <div class="lableContent">
                  {{ selectEditMsg.titleEnDuringProbation }}
                </div>
              </div>
            </div>
            <div class="flex flexBetw AlCenter flex-1 rowItem">
              <div class="flex-1 flexRow">
                <div class="lableDetail">Title CN(after {{probation}})</div>
                <div class="lableContent">
                  {{ selectEditMsg.titleCnAfterProbation }}
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">Title EN(after {{probation}})</div>
                <div class="lableContent">
                  {{ selectEditMsg.titleEnAfterProbation }}
                </div>
              </div>
<!--              <div class="flex-1 flexRow">-->
<!--                <div class="lableDetail">Assessment Period (Monthly)</div>-->
<!--                <div class="lableContent">-->
<!--                  {{ selectEditMsg.assessmentPeriod }}-->
<!--                </div>-->
<!--              </div>-->
              <div class="flex-1 flexRow">
                <div class="lableDetail">Contractual Bonus Plan</div>
                <div class="lableContent">
                  {{ selectEditMsg.contractualBonusPlan }}
                </div>
              </div>
            </div>
<!--            <div class="flex flexBetw AlCenter flex-1 rowItem">-->
<!--              <div class="flex-1 flexRow">-->
<!--                <div class="lableDetail">Notice Period(within assessment)</div>-->
<!--                <div class="lableContent">-->
<!--                  {{ selectEditMsg.noticePeriodWithAssessment }}-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="flex-1 flexRow">-->
<!--                <div class="lableDetail">Title EN(after probation)</div>-->
<!--                <div class="lableContent">-->
<!--                  {{ selectEditMsg.titleEnAfterProbation }}-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="flex-1 flexRow">-->
<!--                <div class="lableDetail">Assessment Period (Monthly)</div>-->
<!--                <div class="lableContent">-->
<!--                  {{ selectEditMsg.assessmentPeriod }}-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="flex flexBetw AlCenter flex-1 rowItem">-->
<!--              <div class="flex-1 flexRow">-->
<!--                <div class="lableDetail">Notice Period(within assessment)</div>-->
<!--                <div class="lableContent">-->
<!--                  {{ selectEditMsg.noticePeriodWithAssessment }}-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="flex-1 flexRow">-->
<!--                <div class="lableDetail">Title EN(after probation)</div>-->
<!--                <div class="lableContent">-->
<!--                  {{ selectEditMsg.titleEnAfterProbation }}-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="flex-1 flexRow">-->
<!--                <div class="lableDetail">Title EN(during assessment)</div>-->
<!--                <div class="lableContent">-->
<!--                  {{ selectEditMsg.titleEnDuringAssessment }}-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="flex flexBetw AlCenter flex-1 rowItem">-->
<!--              <div class="flex-1 flexRow">-->
<!--                <div class="lableDetail">Title CN(after assessment)</div>-->
<!--                <div class="lableContent">-->
<!--                  {{ selectEditMsg.titleCnAfterAssessment }}-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="flex-1 flexRow">-->
<!--                <div class="lableDetail">Title EN(after assessment)</div>-->
<!--                <div class="lableContent">-->
<!--                  {{ selectEditMsg.titleEnAfterAssessment }}-->
<!--                </div>-->
<!--              </div>-->

<!--            </div>-->
            <div class="flex flexBetw AlCenter flex-1 rowItem">
              <div class="flex-1 flexRow">
                <div class="lableDetail">Contractual Target Bonus %</div>
                <div class="lableContent">
                  {{ selectEditMsg.contractualTargetBonus }}
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">Regular/ Temporary</div>
                <div class="lableContent">
                  {{ selectEditMsg.regularTemporary }}
                </div>
              </div>
              <div class="flex-1 flexRow"></div>
            </div>
          </div>
        </el-form>
      </div>
      <div>
        <div class="inductionTitleLab inductionTitleMsg">其他</div>
        <el-form class="filter-form rpm-query-from">
          <div class="flex-1">
            <div class="flex flexBetw AlCenter flex-1 rowItem">
              <div class="flex-1 flexRow">
                <div class="lableDetail">Challenge Group</div>
                <el-tooltip
                  :content="selectEditMsg.challengeGroup"
                  placement="top"
                >
                  <div class="lableContent">
                    {{ selectEditMsg.challengeGroup }}
                  </div>
                </el-tooltip>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">Employee Type</div>
                <el-tooltip
                  :content="selectEditMsg.employeeTypeName"
                  placement="top"
                >
                  <div class="lableContent">
                    {{ selectEditMsg.employeeTypeName }}
                  </div>
                </el-tooltip>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">Original Start Date</div>
                <el-tooltip
                  :content="selectEditMsg.originalStartDate"
                  placement="top"
                >
                  <div class="lableContent">
                    {{ selectEditMsg.originalStartDate }}
                  </div>
                </el-tooltip>
              </div>
            </div>
            <div class="flex flexBetw AlCenter flex-1 rowItem">
              <div class="flex-1 flexRow">
                <div class="lableDetail">Relocation</div>
                <div class="lableContent">
                  {{ selectEditMsg.relocation }}
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">Home City</div>
                <div class="lableContent">
                  {{ selectEditMsg.homeCity }}
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="flex-1 flexRow">
                  <div class="lableDetail">Maximum Moving Cost</div>
                  <div class="lableContent">
                    {{ selectEditMsg.maximumMovingCost }}
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flexBetw AlCenter flex-1 rowItem">
              <div class="flex-1 flexRow">
                <div class="lableDetail">
                  Maximum Temporary Accommodation cost
                </div>
                <div class="lableContent">
                  {{ selectEditMsg.maximumTemporaryAccommodationCost }}
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">Home Trip</div>
                <div class="lableContent">
                  {{ selectEditMsg.homeTrip }}
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">With Spouse</div>
                <div class="lableContent">
                  {{ selectEditMsg.withSpouse }}
                </div>
              </div>
            </div>
            <div class="flex flexBetw AlCenter flex-1 rowItem">
              <div class="flex-1 flexRow">
                <div class="lableDetail">Additional Commercial Insurance</div>
                <el-tooltip
                  :content="selectEditMsg.additionalCommercialInsurance"
                  placement="top"
                >
                  <div class="lableContent lableContentnone">
                    {{ selectEditMsg.additionalCommercialInsurance }}
                  </div>
                </el-tooltip>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">For employee only</div>
                <el-tooltip
                  :content="selectEditMsg.forEmployeeOnly"
                  placement="top"
                >
                  <div class="lableContent lableContentnone">
                    {{ selectEditMsg.forEmployeeOnly }}
                  </div>
                </el-tooltip>
              </div>
              <div class="flex-1 flexRow">
                <div class="lableDetail">Insurance Applicant</div>
                <el-tooltip
                  :content="selectEditMsg.insuranceApplicant"
                  placement="top"
                >
                  <div class="lableContent lableContentnone">
                    {{ selectEditMsg.insuranceApplicant }}
                  </div>
                </el-tooltip>
              </div>
            </div>
          </div>
        </el-form>
      </div>
    </div>
    <!-- 修改时间区域 -->
    <edit-time-management-modal
      v-if="editTimeFlag"
      :rowId="this.rowId"
      :fromPage="'candidate'"
      @closeModal="closeModal"
    ></edit-time-management-modal>
  </div>
</template>

<script>
import editTimeManagementModal from '../salaryManagement/components/modal/editTimeManagementModal.vue'
import { upCase } from "@/utils/util";
const { Vuex } = $PCommon
const { mapGetters } = Vuex
const { PocResetMessage, $sessionStorage } = $PCommon
export default {
  name: 'userManage',
  mixins: [$PCommon.TableMixin],
  components: {
    editTimeManagementModal
  },
  data() {
    return {
      selectEditMsg: {}, // 选中修改的一系列用户信息
      selectIdArr: [], // 选中数组id集合
      chooseTab: '1', // 默认选中已分配机构
      statusList: [
        { id: 0, name: '启用' },
        { id: 1, name: '停用' }
      ],
      failturesList: [
        { id: 0, name: '未锁定' },
        { id: 1, name: '已锁定' }
      ],
      endTimeRemind: '',
      singleId: '',
      appName: '',
      addFlag: true,
      value: [],
      editApplyId: '', // 编辑选中的楼层ID
      buttonDisable: false, // 按钮默认可点
      selectFlag: false, // 编辑的时候是否选择多选框
      editTimeFlag: false,
      props: { multiple: true },
      timeStatus: false
    }
  },
  watch: {
    buttonActive(newValue, oldValue) {
      this.initData(newValue)
    }
  },

  props: {
    buttonActive: {
      type: [String, Number]
    },
    jumpParams: {
      type: Object
    }
  },
  computed: {
    probation(){
      if(this.selectEditMsg.rejoinEmployee === '是'){
        return 'assessment'
      }
      return 'probation'
    }
  },

  created() {
    if (this.buttonActive == '1') {
      this.getHcInfoMsg(this.jumpParams.hiringId)
    } else {
      this.getOfferProposal(
        this.jumpParams.hiringId,
        this.jumpParams.offerProposalId
      )
    }
  },
  methods: {
    upCase,
    initData(newValue) {
      if (newValue == '1') {
        //传hiringId
        this.getHcInfoMsg(this.jumpParams.hiringId)
      } else if (newValue == '2') {
        this.getOfferProposal(
          this.jumpParams.hiringId,
          this.jumpParams.offerProposalId
        )
      }
    },
    editJoinDate() {
      // 增加日期大于当前日期才能修改
      if (!this.timeStatus) return
      this.editTimeFlag = true
      this.rowId = this.selectEditMsg.id
    },
    closeModal() {
      this.editTimeFlag = false
      // 新增编辑完成后刷新基本信息（主要为了更新时间）
      this.getOfferProposal(
        this.jumpParams.hiringId,
        this.jumpParams.offerProposalId
      )
    },
    async getHcInfoMsg(id) {
      const { data, code, msg } = await this.$Api.induction.getHcInfoMsg({
        id: id
      })
      if (code === 200) {
        data.rafApprovedDate = data.rafApprovedDate
          ? data.rafApprovedDate.slice(0, 10)
          : ''
        data.relationshipEffectiveStartDate =
          data.relationshipEffectiveStartDate
            ? data.relationshipEffectiveStartDate.slice(0, 10)
            : ''
        data.openLessThan90daysOrFreelanceUtillized =
          data.openLessThan90daysOrFreelanceUtillized ? '是' : '否'
        data.inFinalRecruitmentStage = data.inFinalRecruitmentStage
          ? '是'
          : '否'
        data.budgetWithinLastRf = data.budgetWithinLastRf ? '是' : '否'
        data.hireDoesNotIncreaseCapacity = data.hireDoesNotIncreaseCapacity
          ? '是'
          : '否'
        data.bill100Percent = data.bill100Percent ? '是' : '否'
        data.offered13thSalary = data.thirteenSalary ? '是' : '否'

        data.estimatedStartDate = data.estimatedStartDate
          ? data.estimatedStartDate.slice(0, 10)
          : ''

        this.selectEditMsg = data
      } else {
        this.$message({
          type: 'error',
          message: msg
        })
      }
    },
    async getOfferProposal(hiringId, offerProposalId) {
      const { data, code, msg } = await this.$Api.induction.getOfferProposal({
        hiringId: hiringId,
        offerProposalId: offerProposalId
      })
      if (code === 200) {
        //净化数据
        data.rafApprovedDate = data.rafApprovedDate
          ? data.rafApprovedDate.slice(0, 10)
          : ''
        data.relationshipEffectiveStartDate =
          data.relationshipEffectiveStartDate
            ? data.relationshipEffectiveStartDate.slice(0, 10)
            : ''
        this.selectEditMsg = data
        console.log(this.selectEditMsg, '----------------')
        // 新增判断日期是否大于当前2021.1.11
        var myDate = new Date()
        console.log(
          '----',
          myDate.getFullYear() +
            '-' +
            (myDate.getMonth() + 1) +
            '-' +
            myDate.getDate()
        )
        var dateStr = new Date(
          myDate.getFullYear() +
            '/' +
            (myDate.getMonth() + 1) +
            '/' +
            myDate.getDate()
        )
        var newDate = new Date(dateStr)
        var proposedOnboardDate = new Date(
          this.selectEditMsg.proposedOnboardDate
        )
        console.log(newDate)
        console.log(proposedOnboardDate)
        console.log(newDate > proposedOnboardDate)
        if (newDate > proposedOnboardDate) {
          this.timeStatus = false
        } else {
          this.timeStatus = true
        }
      } else {
        this.$message({
          type: 'error',
          message: msg
        })
      }
    }
  },
  beforeDestroy() {},
  mounted() {}
}
</script>
<style lang="scss" scoped>
.rowItemindex1 {
  margin-top: 20px;
}
.rowItem {
  margin-top: 12px;
}
.scrollContainer {
  overflow: auto;
  padding-bottom: 20px;
  box-sizing: border-box;
  min-width: 900px;
}
.iconImg {
  width: 16px;
  height: 16px;
}
.inductionTitleMsg {
  font-size: 16px;
  color: #303133;
  font-weight: bold;
  padding: 20px 0 2px 0px;
  box-sizing: border-box;
}

.lableDetail {
  width: 140px;
  color: #868686;
  // font-family: PingFangSC-Regular, PingFang SC;
  /* width: -webkit-fit-content; */
  width: -moz-fit-content;
  /* word-break: break-all; */
  // max-width: 70%;
  // border: 1px solid red;
}
.lableContentSmall {
  width: 50px;
  margin-left: 12px;
}
.lableContentnone {
  width: 100%;
}
.lableContent {
  // border: 1px solid red;
  color: #1b1d1e;
  margin-left: 12px;
  font-weight: 500;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.lableContent span {
  color: #1b1d1e;
  margin-left: 12px;
  // font-family: PingFangSC-Regular, PingFang SC;
}
.flexRow {
  width: 100%;
  overflow: hidden;
  margin-right: 24px;
  // background: red;
}
</style>
