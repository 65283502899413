<script setup>
import { onMounted, reactive, ref } from "vue";
import induction from "@/api/induction"
const emit = defineEmits([ "commit" ]);

let title = ref('拒绝原因 Reject Reason')
let dialogVisible = ref(false)
let queryLoading = ref(false)
let rules= ref({reasonId:[{ required: true, message: '请输入活动名称', trigger: 'blur' },]})
let formOption = reactive({reasonList:[]})
let formData = reactive({data:{
    id:'',
    reasonId:'',
    remark:'',
  }})
const show = (val) => {
  if(val){
    formData.data.id = val.id
    formData.data.reasonId = val.reasonId
    formData.data.remark = val.remark
  }
  dialogVisible.value = true
  getReasonList()
}
const close = () => {
  formData.data ={reasonId:'',remark:'', }
  queryLoading.value = false
  dialogVisible.value = false
}
const getReasonList = () =>{
  induction.dictList({codeSchema:'turn_down_offer_reason'}).then(({code,data})=>{
    if(code === 200){
      formOption.reasonList = data || []
    }
  })
}
const commit = () =>{
  queryLoading.value = true
  emit('commit',formData.data)
}
onMounted(()=>{
  getReasonList()
})
defineExpose({ show,close,queryLoading });

</script>

<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="900"
    class="turnDownOfferDialog"
    append-to-body
    destroy-on-close
    :close-on-click-modal="false"
    :before-close="close"
  >
    <div>
      <el-form
            :model="formData.data"
            label-width="100px"
            :rules="rules"
      >
        <el-form-item label="原因Reason：" prop="reasonId">
          <el-select
            v-model="formData.data.reasonId"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in formOption.reasonList"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注Remark：">
          <el-input
            placeholder="请输入详细原因"
            v-model="formData.data.remark"
            type="textarea"
            :autosize="{ minRows: 5,}"
            maxlength="500"
            show-word-limit
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="btnBox">
      <div>
        <el-button
          class="buttonUnactive"
          @click="close"
        >取消</el-button>
        <el-button
          class="buttonUnactive"
          type="primary"
          :loading="queryLoading"
          @click="commit"
        >确定</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<style scoped lang="scss">
.turnDownOfferDialog{
  .btnBox{
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: end;
    padding-bottom: 20px;
  }
  .el-form .el-form-item {
    margin-right: 0 !important;
  }
  .el-select{
    width: 290px;
  }
}
</style>
