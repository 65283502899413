import {checkPhone} from "@/utils/util";

let requiredArr = [
  'englishName',
  'localName',
  'idPassportNo',
  'joinDate',
  'mobileNumber',
  'businessMail',
  'personalMailAddress',
]

var validatePhone = (rule, value, callback) => {
  var res = checkPhone(value);
  if (!res) {
    callback(new Error('手机格式不正确'));
  }
  callback();
};
var EnglishName = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('EnglishName为空'));
  } else {
    const chineseReg = RegExp(/[\u4E00-\u9FA5]/g)
    if (chineseReg.test(value)) {
      callback(new Error('输入不可为中文'));
    }
    callback();
  }
};

export const rules = {}
requiredArr.forEach((item) => {
  rules[item] = [{required: true, trigger: 'blur'}]
  if (item == 'mobileNumber') {
    rules[item] = [{validator: validatePhone, required: true, trigger: 'blur'}]
  }
  if (item == 'englishName') {
    rules[item] = [{validator: EnglishName, required: true, trigger: 'blur'}]
  }
  if(item == 'businessMail'){
    rules[item] = [ { type: 'email', message: 'Email format error', trigger: ['blur', 'change']}]
  }
  if(item == 'personalMailAddress'){
    rules[item] = [ { type: 'email', message: 'Email format error', trigger: ['blur', 'change']}]
  }
})


