<template>
  <div class="EmploymentHistory">
    <div class="buttonTable">
      <el-button type="primary" @click="sendBackTone">发送背调</el-button>
      <el-button type="primary" @click="add">新增</el-button>
    </div>
    <PocTable
      ref="tTableRef"
      v-loading="loading"
      :data="tableData"
      :current-page.sync="pageConfig.pageNum"
      :total="pageConfig.total"
      stripe
      hidepage
      size="large"
      @size-change="pageSizeChange"
      @page-current-change="pageChange"
      highlight-current-row
    >
      <!--            @selection-change="handleSelectionChange"-->
      <!--      <el-table-column type="selection" fixed width="50" />-->
      <el-table-column
        v-for="item in columnConfig"
        :key="item.key"
        :prop="item.key"
        :fixed="item.fixed"
        :sortable="item.sortable"
        :width="item.key === 'no' ? 60 : null"
        :min-width="item.width"
        show-overflow-tooltip
      >
        <template v-slot:header>
          <div class="center">{{ item.nameZn }}</div>
          <div class="center">{{ item.name }}</div>
        </template>
      </el-table-column>

      <el-table-column
        label="Operation"
        width="130"
        fixed="right"
        align="center"
      >
        <template slot-scope="scope">
          <svg-icon
            icon="tableEdit"
            class="tablePointSvgClass"
            title="编辑"
            @click.native="onEdit(scope.row)"
          />
          <el-popconfirm
            title="确认删除?"
            confirm-button-text="确认"
            cancel-button-text="取消"
            @confirm="deleteItem(scope.row)"
          >
            <svg-icon
              slot="reference"
              icon="delete"
              class="tablePointSvgClass"
              title="删除"
            />
          </el-popconfirm>
        </template>
      </el-table-column>
    </PocTable>
    <addEmploymentHistoryDialog
      ref="addEmploymentHistoryDialogRef"
      @onConfirm="onConfirm"
    />
  </div>
</template>

<script>
import { columnConfig } from '@/views/inductionManage/components/columnConfig'
import {
  employmentHistoryDelete,
  employmentHistoryEdit,
  employmentHistoryList,
  employmentHistorySendBackEmail
} from '@/api/EmploymentHistory'
import addEmploymentHistoryDialog from '@/views/inductionManage/components/AddEmploymentHistoryDialog'
export default {
  name: 'EmploymentHistory',
  mixins: [$PCommon.TableMixin],
  components: {
    addEmploymentHistoryDialog
  },
  props: {
    jumpParams: {
      type: Object
    }
  },
  computed: {
    isShow() {
      return (
        this.jumpParams.employmentHistory &&
        this.jumpParams.employmentHistory === 'details'
      )
    }
  },
  created() {
    this.queryApi()
  },
  data() {
    return {
      columnConfig: columnConfig,
      tableData: [
        {
          backTime: '',
          companyName: '',
          createTime: '',
          hrEmail: '',
          hrName: '',
          hrPhone: '',
          hrUuid: '',
          id: 0,
          lastJobTitle: '',
          leaveComments: '',
          leaveReason: '',
          lineManagerEmail: '',
          lineManagerName: '',
          lineManagerPhone: '',
          lineManagerUuid: '',
          offerProposalId: 0,
          outerId: 0,
          periodEnd: '',
          periodStart: ''
        }
      ],
      loading: false,
      selectVal: [],
      TipStatus: ''
    }
  },
  methods: {
    onEdit(val) {
      this.TipStatus = 'edit'
      this.$refs.addEmploymentHistoryDialogRef.show({
        editStatus: 'edit',
        id: val.id,
        offerProposalId: val.offerProposalId
      })
    },
    deleteItem(val) {
      const data = {
        id: val.id
      }
      employmentHistoryDelete(data).then((res) => {
        if (res && res.code === 200) {
          this.$message({
            type: 'success',
            message: '删除成功'
          })
          this.queryApi()
        }
      })
    },
    handleSelectionChange(val) {
      this.selectVal = val
    },
    sendBackTone() {
      const data = {
        id: this.jumpParams.offerProposalId
      }
      employmentHistorySendBackEmail(data).then((res) => {
        if (res && res.code === 200) {
          this.$message({
            type: 'success',
            message: '发送背调成功'
          })
          this.queryApi()
        }
      })
    },
    add() {
      this.TipStatus = 'add'
      this.$refs.addEmploymentHistoryDialogRef.show({
        editStatus: 'add',
        id: '',
        offerProposalId: this.jumpParams.offerProposalId
      })
    },
    queryApi() {
      const data = {
        id: this.jumpParams.offerProposalId
      }
      employmentHistoryList(data).then((res) => {
        if (res && res.code === 200) {
          this.tableData = res.data
        }
      })
    },
    onConfirm(val) {
      if (val) {
        employmentHistoryEdit(val).then((res) => {
          if (res && res.code === 200) {
            this.$message({
              type: 'success',
              message: this.TipStatus === 'add' ? '新增成功' : '编辑成功'
            })
            this.queryApi()
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.EmploymentHistory {
  .buttonTable {
    text-align: right;
    margin: 20px 0 20px 0;
    :deep(.el-button) {
      &:last-child {
        margin-right: 0 !important;
      }
    }
  }
  .center {
    //text-align: center;
  }
  ::v-deep .el-table .sort-caret.ascending{
    top:-25px
  }
  ::v-deep .el-table .sort-caret.descending{
    bottom: 8px;
  }
  ::v-deep .el-table .caret-wrapper{
    display: block;
    height: 5px;
    left: 75px;
  }
}
</style>
