<template>
  <div class="editTimeManagementModal">
    <el-dialog
      :visible.sync="dialogVisible"
      width="400px"
      :title="modalTitle"
      @close="close"
    >
      <div style="text-align: center; font-size: 20px">
        <div class="selectBox flexRow AlCenter">
          <div class="flex-1">
            <el-form>
              <div class="flex-1">
                <el-form-item label="Date" class="w100" prop="BPName">
                  <el-date-picker
                    style="width: 100%"
                    v-model="selectEditMsg.EstimatedDate"
                    :start-placeholder="2023 - 1 - 13"
                    type="date"
                    align="right"
                    :clearable="true"
                    range-separator="-"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
      </div>
      <div style="text-align: right; margin-top: 8px">
        <el-button
          type="primary"
          class="buttonunActive"
          @click="close"
          style="margin-bottom: 20px"
          >取 消</el-button
        >
        <el-button
          :loading="isLoading"
          type="primary"
          class="buttonActive"
          @click="sure"
          style="margin-bottom: 20px"
          >确 定</el-button
        >
        <div class="clear"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
const { Vuex } = $PCommon

export default {
  name: 'userManage',
  mixins: [$PCommon.TableMixin],
  components: {},
  props: {
    rowId: {
      type: String
    },
    fromPage: {
      type: String
    }
  },

  data() {
    return {
      modalTitle: 'Estimated Start Date',

      dialogVisible: true,
      queryLoading: false,
      selectEditMsg: {
        EstimatedDate: ''
      },
      isLoading:false
    }
  },
  computed: {},

  async created() {},
  methods: {
    async sure() {
      let str = this.selectEditMsg.EstimatedDate;
      var myDate = new Date()
      var dateStr = new Date(myDate.getFullYear() + '-' + (myDate.getMonth() + 1) + '-' + myDate.getDate());
      var newDate = new Date(dateStr);
        var proposedOnboardDate = new Date(str.replace(/-/g,"/"));
      console.log('====', this.selectEditMsg)
      console.log('====', newDate)
      console.log('====', proposedOnboardDate)

      if (newDate > proposedOnboardDate) {
        this.$message({
          type: 'warning',
          message: '修改时间不能小于当前日期'
        })
      } else {
        this.isLoading = true;
        if (this.fromPage == 'candidate') {
          this.editcandidateDate()
        } else {
          this.editOp111StartDate()
        }
      }
    },
    async editOp111StartDate() {
      this.queryLoading = true
      const { data, code } = await this.$Api.salary.editOp111StartDate({
        id: this.rowId,
        startDate: this.selectEditMsg.EstimatedDate
      })
      this.isLoading = false;
      if (code === 200) {
        this.$message({
          type: 'success',
          message: '编辑成功'
        })
        this.close()
      } else {
        this.$message({
          type: 'success',
          message: '编辑失败'
        })
        this.close()
      }
    },

    async editcandidateDate() {
      this.queryLoading = true
      const { data, code } = await this.$Api.induction.editJoinDate({
        id: this.rowId,
        joinDate: this.selectEditMsg.EstimatedDate
      })
      this.isLoading = false;
      if (code === 200) {
        this.$message({
          type: 'success',
          message: '修改成功'
        })
        this.close()
      } else {
        this.$message({
          type: 'success',
          message: '修改失败'
        })
        this.close()
      }
    },

    close() {
      this.dialogVisible = false
      this.$emit('closeModal')
    }
  },
  beforeDestroy() {},
  mounted() {}
}
</script>
<style lang="scss" scoped>
.w100 {
  width: 100%;
}
.modalTitle {
  width: 100%;
  padding-bottom: 20px;
  box-sizing: border-box;
  margin-bottom: 10px;
  border-bottom: 1px solid #777;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

.flexRow {
  display: flex;
  flex-direction: row;
}
.flexCenter {
  justify-content: center;
  align-items: center;
}
.AlCenter {
  align-items: center;
}
.buttonunActive {
  width: 68px;
  height: 30px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ba9765;
  border-radius: 4px;
  color: #ba9765;
  font-size: 14px;
  line-height: 15px;
}
.buttonActive {
  // width: 68px;
  height: 30px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 15px;
}
::v-deep .el-form .el-form-item {
  margin-bottom: 16px !important;
}
::v-deep .el-dialog__header {
  padding: 20px;
}
</style>
<style lang="scss">
.editTimeManagementModal {
  .el-dialog__title {
    line-height: 24px;
    font-size: 21px;
    color: #303133;
  }
  .el-dialog .el-dialog__body {
    padding: 0 20px 20px 20px;
  }
  .el-button + .el-button {
    color: #303133;
  }

  .el-button + .el-button {
    margin-left: 12px;
  }
  .el-select {
    width: 100%;
  }
}
</style>
