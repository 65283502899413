import request from '@/utils/request'

export const employmentHistoryList = (data) => {
  return request({
    url: '/employmentHistory/list',
    params: {
      ...data
    }
  })
}
export const employmentHistoryEdit = (data) => {
  return request({
    url: '/employmentHistory/edit',
    method: 'POST',
    data
  })
}
export const employmentHistoryDetail = (data) => {
  return request({
    url: '/employmentHistory/detail',
    params: {
      ...data
    }
  })
}
export const employmentHistoryDelete = (data) => {
  return request({
    url: '/employmentHistory/delete',
    params: {
      ...data
    }
  })
}
export const employmentHistorySendBackEmail = (data) => {
  return request({
    url: '/employmentHistory/sendBackEmail',
    params: {
      ...data
    }
  })
}
