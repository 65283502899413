<template>
  <!-- 查看详情区域 -->
  <div class="backInputContainer">
    <div class="cardItem">
      <div class="nameTitleBox">
        <div class="h3Title">Reference Check Questionnaire</div>
        <el-row :gutter="12" class="rowBox">
          <el-col :md="24" :lg="12" :xl="12">
            <div class="lableTitle">Your name 您的姓名</div>
            <el-input
              placeholder=""
              v-model="filterConfig.englishName"
            ></el-input
            >   
          </el-col>
          <el-col :md="24" :lg="12" :xl="12">
            <div class="lableTitle">Phone Number & WeChat 电话号码&微信</div>
            <el-input
              placeholder=""
              v-model="filterConfig.englishName"
            ></el-input
            >   
          </el-col>
          <el-col :md="24" :lg="12" :xl="12">
            <!-- <div class=""> </div> -->
            <div class="lableTitle">
              Current Company & Title 目前在职公司&岗位
            </div>
            <el-input placeholder="" v-model="filterConfig.englishName">
            </el-input
            >     
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="carditemBox">
      <div class="borderBox"></div>
      <div class="backPeopleBox">
        <div class="questionItem">
          <div class="questionTitle2">
            1. What was your professional relationship to candidate?
            请问候选人与您的工作关系是？
          </div>
          <div class="questionContent">
            <el-radio-group v-model="radio">
              <el-radio :label="3">Direct Line Manager 直属领导</el-radio>
              <el-radio :label="6">HR</el-radio>
              <el-radio :label="6">Colleague 同事</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="questionItem">
          <div class="questionTitle">
            2. What are the start and end dates for the candidate time working
            at your company and his/her
            position?候选人服务您公司时间自何时开始到何时结束，他/她当时的职位是？
          </div>
          <div class="DateBox">
            <el-row :gutter="12">
              <el-col :md="24" :lg="8" :xl="8">
                <div class="PositionTitle2">Start Date 开始日期</div>
                <el-date-picker
                  style="width: 100%"
                  v-model="dateRange"
                  type="date"
                  align="right"
                  :clearable="false"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker
              ></el-col>
              <el-col :md="24" :lg="8" :xl="8">
                <div class="PositionTitle2">End Date 结束日期</div>
                <el-date-picker
                  style="width: 100%"
                  v-model="dateRange"
                  type="date"
                  align="right"
                  :clearable="false"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker
              ></el-col>
              <el-col :md="24" :lg="8" :xl="8">
                <div class="PositionTitle2">Position 职位</div>
                <el-input
                  placeholder="请输入English Name"
                  v-model="filterConfig.englishName"
                >
                      </el-input
                >     
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="questionItem">
          <div class="questionTitle2">
            3. What can you tell me about candidate’s knowledge and performance
            in this position?
            请您描述下候选人在他/她当时岗位上的技能掌握和工作表现？
          </div>

          <div class="questionContent">
            <el-radio-group v-model="radio">
              <el-radio :label="3">Excellent 优秀</el-radio>
              <el-radio :label="6">Good 较好</el-radio>
              <el-radio :label="6">Average 一般</el-radio>
              <el-radio :label="6">Below Average 不佳</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="questionItem">
          <div class="questionTitle2">
            4. How did he/she get along with others?
            请问候选人与公司其他员工相处得如何？
          </div>

          <div class="questionContent">
            <el-radio-group v-model="radio">
              <el-radio :label="3">Excellent 优秀</el-radio>
              <el-radio :label="6">Good 较好</el-radio>
              <el-radio :label="6">Average 一般</el-radio>
              <el-radio :label="6">Below Average 不佳</el-radio>
              <!-- <el-radio :label="6">Not responsibility 不承担管理职责</el-radio> -->
            </el-radio-group>
          </div>
          <div class="textareaContent">
            <div class="PositionTitle">Additional Comments 补充说明</div>
            <el-input placeholder="" v-model="filterConfig.englishName">
            </el-input
            > 
          </div>
        </div>
        <div class="questionItem">
          <div class="questionTitle2">
            5.Did he/she supervise any staff? If yes, how did he/she perform?
            请问候选人是否担任管理职责？如果是的话，他/她的管理能力如何？
          </div>
          <div class="questionContent">
            <el-radio-group v-model="radio">
              <el-radio :label="3">Excellent 优秀</el-radio>
              <el-radio :label="6">Good 较好</el-radio>
              <el-radio :label="6">Average 一般</el-radio>
              <el-radio :label="6">Below Average 不佳</el-radio>
              <el-radio :label="6">Not responsibility 不承担管理职责</el-radio>
            </el-radio-group>
          </div>
          <div class="textareaContent">
            <div class="PositionTitle">Additional Comments 补充说明</div>
            <el-input placeholder="" v-model="filterConfig.englishName">
            </el-input
            > 
          </div>
        </div>
        <div class="questionItem">
          <div class="questionTitle2">
            6. Did the candidate voluntary resign from the position?
            候选人是主动请辞该职位的吗？
          </div>
          <div class="questionContent">
            <el-radio-group v-model="radio">
              <el-radio :label="3">Yes 是</el-radio>
              <el-radio :label="3">No 不是</el-radio>
              <el-radio :label="6">
                Contract period over and did not renew 合约到期不续签</el-radio
              >
            </el-radio-group>
          </div>
        </div>
        <div class="questionItem">
          <div class="questionTitle2">
            7. Did the candidate achieve any special award or get disciplinary
            actions? 候选人是否获得过特殊奖励或受到纪律处分?
          </div>
          <div class="questionContent flexRow">
            <el-radio-group v-model="radio">
              <el-radio :label="3">Award 奖励</el-radio>
              <el-radio :label="3">Disciplinary 纪律处分</el-radio>
              <el-radio :label="6">No thing 无</el-radio>
            </el-radio-group>
          </div>
          <div class="textareaContent">
            <div class="PositionTitle">Additional Comments 补充说明</div>
            <el-input placeholder="" v-model="filterConfig.englishName">
            </el-input
            > 
          </div>
        </div>

        <div class="questionItem">
          <div class="questionTitle2">
            8. Would you re-hire/work with/work for the candidate again? Why/why
            not? 你会选择再次与候选人共事吗？为什么？
          </div>
          <div class="questionContent flexRow">
            <el-radio-group v-model="radio">
              <el-radio :label="3">YES 会</el-radio>
              <el-radio :label="6">NO 不会</el-radio>
            </el-radio-group>
          </div>
          <div class="textareaContent">
            <div class="PositionTitle">Reason 理由</div>
            <el-input placeholder="" v-model="filterConfig.englishName">
            </el-input
            > 
          </div>
        </div>
        <div class="questionItem">
          <div class="questionTitle">
            9. Is there anything else we should take into consideration before
            hiring this candidate?
            在我们雇佣这位候选人之前还有什么我们应该考虑在内的？
          </div>
          <div class="textareaContent">
            <el-input placeholder="" v-model="filterConfig.englishName">
            </el-input
            > 
          </div>
        </div>
        <div class="questionItem">
          <div class="questionTitle">
            10. Any weakness/area of further improvements?
            您觉得他/她有什么弱点或是尚待改进之处？
          </div>
          <div class="textareaContent">
            <el-input placeholder="" v-model="filterConfig.englishName">
            </el-input
            > 
          </div>
        </div>
        <div class="questionItem">
          <div class="questionTitle">
            11. Is there anything else we should take into consideration before
            hiring this candidate?
            在我们雇佣这位候选人之前还有什么我们应该考虑在内的？
          </div>
          <div class="textareaContent">
            <el-input placeholder="" v-model="filterConfig.englishName">
            </el-input
            >   
          </div>
        </div>
        <div class="saveButton">
          <div>提 交</div>
          <div>submit</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'userManage',

  components: {},

  data() {
    return {
      selectEditMsg: {
        backedName: '证明人杨 测试信息岗位',
        assignedRoleList: '',
        beginTime: '',
        email: '',
        endTime: '',
        mobile: '',
        remark: '',
        status: '',
        userCode: 'L0787878787878787878787ryManager(L3)',
        userName: '张三'
      } // 选中修改的一系列用户信息
    }
  },

  computed: {},

  created() {},
  methods: {
    dealStatus(status) {
      switch (status) {
        case '0': {
          return '有效'
        }
        case '1': {
          return '失效'
        }
        default: {
          return '-'
        }
      }
    },
    async getsingleMsg(singleId) {
      const { data, code, msg } = await this.$Api.dataMain.getSingleMsg({
        id: singleId
      })
      if (code === 200) {
        this.selectEditMsg = data
        this.selectEditMsg.status = Number(data.status)
        if (!this.selectEditMsg.endTime) {
          this.endTimeRemind = '永久'
        }
        console.log('单个数据', this.selectEditMsg)
      } else {
        this.$message({
          type: 'error',
          message: msg
        })
      }
    },
    // 关闭侧边栏
    cancle() {
      if (this.addFlag) {
        this.selectEditMsg = {
          beginTime: '',
          email: '',
          endTime: '',
          mobile: '',
          remark: '',
          status: '',
          userCode: '',
          userName: '张丹'
        }
        this.$emit('goback')
      }

      this.selectFlag = false // 重新选择置为false
    }
  },
  beforeDestroy() {},
  mounted() {}
}
</script>
<style lang="scss" scoped>
.backInputContainer {
  width: 100%;
  height: calc(100vh - 100px);
  overflow: auto;
  // border: 1px solid red;
  padding: 0px 180px 0 180px;
  box-sizing: border-box;
}
.cardItem {
  border-radius: 2px;
  color: rgb(122, 135, 142);
  display: block;
  //font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  text-size-adjust: 100%;
  margin-bottom: 20px;
  width: 100%;
  background: #fff;
  padding-bottom: 25px;
  box-sizing: border-box;
}
.nameTitleBox {
  width: 100%;
  text-align: center;
  font-weight: bold;
  .h3Title {
    font-size: 24px;
    font-weight: bold;
    color: #212129;
    // border:1px solid red;
    padding-bottom: 50px;
    padding-top: 80px;
    margin-bottom: 35px;
  }
}
.rowBox {
  padding: 0 20px 0 20px;
  box-sizing: border-box;
}
.lableTitle {
  font-size: 14px;
  color: #212129;
  margin-bottom: 5px;
  margin-top: 15px;
}
.carditemBox {
  border: 0;
  border-radius: 3px;
  box-shadow: none !important;
  margin-bottom: 20px;
  background: #fff;
  // border: 1px solid red;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  margin-top: 30px;
}
.borderBox {
  width: 100%;
  height: 4px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  background: #ba9765;
}
.backPeopleBox {
  padding: 15px 20px 25px 20px;
  box-sizing: border-box;
  margin-top: 30px;
}
.questionItem {
  // padding-top: 50px;
  // border-top: 1px solid red;
  margin-bottom: 30px;
}
.questionTitleBox {
}

.questionTitle {
  font-size: 15px;
  color: #4d627b;
  font-weight: bold;
}
.questionTitle2 {
  font-size: 15px;
  color: #4d627b;
  font-weight: bold;
  width: 60%;
}
.questionContent {
  width: 100%;
  padding: 10px 10px 0 0px;
  box-sizing: border-box;
  overflow: hidden;
  // border: 1px solid red;
}
.dateContent {
  font-size: 13px;
  color: #7a878e;
  margin-top: 10px;
}
.PositionTitle {
  font-size: 14px;
  color: #212179;
  font-weight: bold;
  margin-top: 6px;
  margin-bottom: 4px;
}
.PositionTitle2 {
  font-size: 14px;
  color: #212179;
  font-weight: bold;
  margin-bottom: 4px;
}
.textareaContent {
  width: 100%;
  height: 106px;
  border-radius: 3px;
  color: #555;
  font-size: 13px;
  // padding: 6px 12px;
  box-sizing: border-box;
  margin-top: 10px;
}

.DateBox {
  margin-top: 20px;
}

.w100 {
  width: 100%;
}
.pr24 {
  padding-right: 24px;
  box-sizing: border-box;
}

.flexRow {
  display: flex;
  flex-direction: row;
}
.flexCenter {
  justify-content: center;
  align-items: center;
}
.AlCenter {
  align-items: center;
}
.flexBetw {
  display: flex;
  justify-content: space-between;
}
.flex {
  display: flex;
}
.saveButton {
  width: 170px;
  background-color: #9d833e;
  color: #e7e7e7;
  font-weight: 400;
  font-size: 17px;
  border-radius: 3px;
  text-align: center;
  margin: 0 auto;
  padding: 10px 16px 10px 16px;
  box-sizing: border-box;
}
.saveButton:hover {
  background: #ba9765;
  box-shadow: 3px 0 0 3px #ba9765;
  cursor: pointer;
}
</style>
<style lang="scss">
.backInputContainer {
  .el-card__body {
    padding: 0 10px 20px 10px !important;
    box-sizing: border-box;
  }

  .textareaContent {
    .el-input__inner {
      height: 106px !important;
    }
  }
}
</style>
