<template>
  <el-dialog
    title="The Staff's Employment Experience"
    :visible.sync="dialogVisible"
    custom-class="AddEmploymentHistoryDialog"
    width="40%"
    append-to-body
    destroy-on-close
    :before-close="close"
  >
    <FormDesigner ref="FormDesignerRef" :formScale="formScale" />
    <div class="bottomButton">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import descriptions from '@/components/descriptions/descriptions'
import FormDesigner from '@/components/FormDesigner/FormDesigner'
import { employmentHistoryDetail } from '@/api/EmploymentHistory'
import { mergeDesDataOfPort } from '@/utils/util'
export default {
  name: 'AddEmploymentHistoryDialog',
  components: {
    descriptions,
    FormDesigner
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      formScale: [
        {
          id: Math.random(),
          gutter: 20,
          colList: [
            {
              span: 12,
              id: Math.random(),
              formData: {
                prop: 'companyName',
                rules: 'required',
                label: 'Company',
                id: Math.random(),
                placeholder: '请选择',
                type: 'input',
                value: ''
              }
            },
            {
              span: 12,
              id: Math.random(),
              formData: {
                prop: 'lastJobTitle',
                rules: 'required',
                label: 'Job Title',
                id: Math.random(),
                type: 'input',
                value: ''
              }
            }
          ]
        },
        {
          span: 24,
          id: Math.random(),
          colList: [
            {
              formData: {
                prop: 'employmentDate',
                rules: '',
                label: 'Employment',
                id: Math.random(),
                type: 'data',
                disType: 'daterange',
                valueFormat: 'yyyy-MM-dd',
                value: '',
                change: () => {}
              }
            }
          ]
        },
        {
          id: Math.random(),
          gutter: 20,
          colList: [
            {
              span: 8,
              id: Math.random(),
              formData: {
                id: Math.random(),
                label: 'Line Manager',
                prop: 'lineManagerName',
                type: 'input',
                rules: 'required',
                value: ''
              }
            },
            {
              span: 8,
              id: Math.random(),
              formData: {
                id: Math.random(),
                label: 'Line Manager Email',
                prop: 'lineManagerEmail',
                type: 'input',
                rules: 'required|email',
                value: ''
              }
            },
            {
              span: 8,
              id: Math.random(),
              formData: {
                id: Math.random(),
                label: 'Line Manager Phone',
                prop: 'lineManagerPhone',
                type: 'input',
                rules: 'integer',
                value: ''
              }
            }
          ]
        },
        {
          id: Math.random(),
          gutter: 20,
          colList: [
            {
              span: 8,
              id: Math.random(),
              formData: {
                span: 8,
                id: Math.random(),
                label: 'HR',
                prop: 'hrName',
                type: 'input',
                rules: 'required',
                value: ''
              }
            },
            {
              span: 8,
              id: Math.random(),
              formData: {
                span: 8,
                id: Math.random(),
                label: 'HR Email',
                prop: 'hrEmail',
                type: 'input',
                rules: 'required|email',
                value: ''
              }
            },
            {
              span: 8,
              id: Math.random(),
              formData: {
                span: 8,
                id: Math.random(),
                label: 'HR Phone',
                prop: 'hrPhone',
                type: 'input',
                rules: 'required|integer',
                value: ''
              }
            }
          ]
        },
        {
          id: Math.random(),
          gutter: 20,
          colList: [
            {
              span: 24,
              id: Math.random(),
              formData: {
                id: Math.random(),
                label: 'Leaving Reason',
                prop: 'leaveReason',
                type: 'select',
                options: [
                  { label: '主动离职', value: '1' },
                  { label: '被动离职', value: '2' }
                ],
                rules: 'required',
                value: '',
                clearable: true,
                filterable: true,
                placeholder: '请选择',
                onClick: () => {},
                change: () => {}
              }
            }
          ]
        },
        {
          id: Math.random(),
          gutter: 20,
          colList: [
            {
              span: 24,
              id: Math.random(),
              formData: {
                id: Math.random(),
                label: 'Leaving Comments',
                prop: 'leaveComments',
                rules: '',
                type: 'input',
                disType: 'textarea',
                value: ''
              }
            }
          ]
        }
      ],
      editStatus: '',
      id: ''
    }
  },
  methods: {
    close() {
      const date = {
        backTime: '',
        companyName: '',
        createTime: '',
        hrEmail: '',
        hrName: '',
        hrPhone: '',
        hrUuid: '',
        id: '',
        lastJobTitle: '',
        leaveComments: '',
        leaveReason: '',
        lineManagerEmail: '',
        lineManagerName: '',
        lineManagerPhone: '',
        lineManagerUuid: '',
        offerProposalId: '',
        outerId: '',
        employmentDate: [],
        periodEnd: '',
        periodStart: ''
      }
      this.$refs.FormDesignerRef.setValue(date)
      this.dialogVisible = false
    },
    show(val) {
      if (val) {
        this.editStatus = val.editStatus
        this.id = val.id
        this.offerProposalId = val.offerProposalId
        if (this.editStatus === 'edit') {
          this.loading = true
          const data = {
            id: this.id
          }
          employmentHistoryDetail(data).then((res) => {
            if (res && res.code === 200) {
              const data = res.data
              data.employmentDate = [data.periodStart, data.periodEnd]
              this.id = data.id
              this.offerProposalId = data.offerProposalId
              this.$refs.FormDesignerRef.setValue(data)
              this.loading = false
            }
          })
        }
        this.dialogVisible = true
      }
    },
    async submit() {
      let data = await this.$refs.FormDesignerRef.getValue()
      if (data) {
        let returnData = JSON.parse(JSON.stringify(data))
        returnData.periodStart = returnData.employmentDate[0]
        returnData.periodEnd = returnData.employmentDate[1]
        returnData.id = this.id
        returnData.offerProposalId = this.offerProposalId
        console.log(returnData)
        this.$emit('onConfirm', returnData)
        this.close()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bottomButton {
  padding: 20px 0 20px 0;
  text-align: right;
}
</style>
