<template>
  <div class="upload-list-page">
    <div class="scrollContainer">
      <div class="uploadItem">
        <div class="flexRow AlCenter">
          <div class="uploadTitle">最高学历证明</div>
          <div class="buttonRightBox flexRow AlCenter">
            <el-upload
              class="buttonunActive"
              action="https://jsonplaceholder.typicode.com/posts/"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :on-change="onChangeFile"
              :file-list="educationFileList"
              :list-type="educationlistType"
            >
              <el-button
                class="buttonActive"
                type="primary"
                @click="uploadType('EducationGraduationCertificate')"
                >上传</el-button
              >
              <!-- <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件或者pdf，且不超过500kb
              </div> -->
            </el-upload>
          </div>
        </div>
      </div>
      <div class="uploadItem">
        <div class="flexRow AlCenter">
          <div class="uploadTitle">学位证书</div>
          <div class="buttonRightBox flexRow AlCenter">
            <el-upload
              class="buttonunActive"
              action="#"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :on-change="onChangeFile"
              :file-list="degreeFileList"
              :list-type="degreelistType"
            >
              <el-button
                class="buttonActive"
                type="primary"
                @click="uploadType('DegreeCertificate')"
                >上传</el-button
              >
              <!-- <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件或者pdf，且不超过500kb
              </div> -->
            </el-upload>
          </div>
        </div>
      </div>
      <div class="uploadItem">
        <div class="flexRow AlCenter">
          <div class="uploadTitle">简历</div>
          <div class="buttonRightBox flexRow AlCenter">
            <el-upload
              class="buttonunActive"
              action="#"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :on-change="onChangeFile"
              :file-list="resumeFileList"
              :list-type="resumelistType"
            >
              <el-button
                class="buttonActive"
                type="primary"
                @click="uploadType('Resume')"
                >上传</el-button
              >
              <!-- <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件或者pdf，且不超过500kb
              </div> -->
            </el-upload>
          </div>
        </div>
      </div>
      <div class="uploadItem">
        <div class="flexRow AlCenter">
          <div class="uploadTitle">回签offer</div>
          <div class="buttonRightBox flexRow AlCenter">
            <el-upload
              class="buttonunActive"
              action="#"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :on-change="onChangeFile"
              :file-list="signedOfferFileList"
              :list-type="signedlistType"
            >
              <el-button
                class="buttonActive"
                type="primary"
                @click="uploadType('SignedOfferLetter')"
                >上传</el-button
              >
              <!-- <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件或者pdf，且不超过500kb
              </div> -->
            </el-upload>
          </div>
        </div>
      </div>
      <div class="uploadItem">
        <div class="flexRow AlCenter">
          <div class="uploadTitle">身份证复印件</div>
          <div class="buttonRightBox flexRow AlCenter">
            <el-upload
              class="buttonunActive"
              action="#"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :file-list="idCardFileList"
              :on-change="onChangeFile"
              :list-type="idCardlistType"
            >
              <el-button
                class="buttonActive"
                type="primary"
                @click="uploadType('IdCard')"
                >上传</el-button
              >
              <!-- <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件或者pdf，且不超过500kb
              </div> -->
            </el-upload>
          </div>
        </div>
      </div>
      <div class="uploadItem">
        <div class="flexRow AlCenter">
          <div class="uploadTitle">戶口本复印件</div>
          <div class="buttonRightBox flexRow AlCenter">
            <el-upload
              class="buttonunActive"
              action="#"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :on-change="onChangeFile"
              :file-list="residenceFileList"
              :list-type="residencelistType"
            >
              <el-button
                class="buttonActive"
                type="primary"
                @click="uploadType('ResidenceDocument')"
                >上传</el-button
              >
              <!-- <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件或者pdf，且不超过500kb
              </div> -->
            </el-upload>
          </div>
        </div>
      </div>
      <div class="uploadItem">
        <div class="flexRow AlCenter">
          <div class="uploadTitle">子女证件复印件</div>
          <div class="buttonRightBox flexRow AlCenter">
            <el-upload
              class="buttonunActive"
              action="#"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :file-list="childrFileList"
              :on-change="onChangeFile"
              :list-type="childlistType"
            >
              <el-button
                class="buttonActive"
                type="primary"
                @click="uploadType('ChildrenIdCard')"
                >上传</el-button
              >
              <!-- <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件或者pdf，且不超过500kb
              </div> -->
            </el-upload>
          </div>
        </div>
      </div>
      <div class="uploadItem">
        <div class="flexRow AlCenter">
          <div class="uploadTitle">银行卡</div>
          <div class="buttonRightBox flexRow AlCenter">
            <el-upload
              class="buttonunActive"
              action="#"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :on-change="onChangeFile"
              :file-list="bankFileList"
              :list-type="banklistType"
            >
              <el-button
                class="buttonActive"
                type="primary"
                @click="uploadType('Bank')"
                >上传</el-button
              >
              <!-- <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件或者pdf，且不超过500kb
              </div> -->
            </el-upload>
          </div>
        </div>
      </div>
      <embed
        type="image/jpg"
        src="https://iris.51front.com/download.pdf"
        width="258"
        height="39"
      />
      <div class="uploadItem">
        <div class="flexRow AlCenter">
          <div class="uploadTitle">体检报告</div>
          <div class="buttonRightBox flexRow AlCenter">
            <el-upload
              class="buttonunActive"
              action="#"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :on-change="onChangeFile"
              :before-remove="beforeRemove"
              :file-list="medicalFileList"
              :list-type="medicallistType"
              accept="image/*,.pdf/"
            >
              <el-button
                class="buttonActive"
                type="primary"
                @click="uploadType('MedicalReport')"
                >上传</el-button
              >
              <!-- <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件或者pdf，且不超过500kb
              </div> -->
            </el-upload>
          </div>
        </div>
      </div>
    </div>
    <!-- //按钮区域，只有编辑时生效 -->
    <!-- <div class="flexRow buttonBottom">
      <el-button type="primary">保存</el-button>
      <el-button type="primary" @click="close">取消</el-button>
    </div> -->

    <el-dialog
      :visible.sync="dialogVisible"
      width="354px"
      height="200px"
      top="230px"
    >
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <el-drawer
      title=""
      :visible.sync="dialogVisible2"
      direction="rtl"
      size="80%"
    >
      <iframe class="pdfBox" :src="pdfUrl" frameborder="0"></iframe>
    </el-drawer>
  </div>
</template>

<script>
const { Vuex } = $PCommon
const { mapGetters } = Vuex

export default {
  name: 'uploadManage',
  components: {},

  data() {
    return {
      fileList: [
        {
          name: 'food.jpeg',
          url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
        },
        {
          name: 'food2.jpeg',
          url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
        }
      ],
      dialogImageUrl: '',
      dialogVisible: false,
      dialogVisible2: false,
      disabled: false,
      uploadLoading: false,
      offerProposalId: '',
      type: '',
      bankFileList: [],
      childrFileList: [],
      degreeFileList: [],
      educationFileList: [],
      idCardFileList: [],
      residenceFileList: [],
      resumeFileList: [],
      signedOfferFileList: [],
      medicalFileList: [],
      educationlistType: 'picture',
      degreelistType: 'picture',
      resumelistType: 'picture',
      signedlistType: 'picture',
      idCardlistType: 'picture',
      residencelistType: 'picture',
      childlistType: 'picture',
      banklistType: 'picture',
      medicallistType: 'picture',
      pdfUrl: ''
    }
  },
  props: {
    jumpParams: {
      type: Object
    }
  },
  computed: {},

  created() {
    console.log('777777', this.jumpParams.offerProposalId)
    this.offerProposalId = this.jumpParams.offerProposalId
    this.getuploadList(this.offerProposalId)
  },
  methods: {
    uploadType(typeName) {
      this.type = typeName
    },
    async getuploadList(id) {
      const { code, data, msg } = await this.$Api.candidate.getuploadList({
        id: this.jumpParams.candidateInformationId
      })
      if (code == 200) {
        let dataTypeArr = []
        console.log('数据-----', data)
        for (var i in data) {
          console.log(i)
          dataTypeArr.push(i)
        }
        dataTypeArr.map((d) => {
          if (data[d]) {
            data[d].map((v) => {
              if (v.fileType == 'image/jpeg') {
                v['url'] = 'https://iris.51front.com' + v.path
              } else {
                v['pdfUrl'] = 'https://iris.51front.com' + v.path
                v['url'] =
                  'https://img0.baidu.com/it/u=1139038387,1432980157&fm=253&app=120&size=w931&n=0&f=JPEG&fmt=auto?sec=1656522000&t=194c947cda52a9340ca2061f2b6c72b3'
              }
            })
          }
        })

        this.bankFileList = data.bank
        this.childrFileList = data.childrenIdCard
        this.degreeFileList = data.degreeCertificate
        this.educationFileList = data.educationGraduationCertificate
        this.idCardFileList = data.idCard
        this.residenceFileList = data.residenceDocument
        this.resumeFileList = data.resume
        this.signedOfferFileList = data.signedOfferLetter
        this.medicalFileList = data.medicalReport
      } else {
        this.$message({
          type: 'info',
          message: msg
        })
      }
    },
    handleRemove(file, fileList) {
      console.log('-----------', file, fileList)
      this.removeUpload(file)
    },
    handlePreview(file) {
      if (file.fileType == 'application/pdf') {
        this.pdfUrl = file.pdfUrl
        this.dialogVisible2 = true
        console.log('上传文件为===', this.pdfUrl)
      } else {
        this.dialogImageUrl = file.url
        this.dialogVisible = true
      }
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      )
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    async removeUpload(file) {
      const { code, data, msg } = await this.$Api.candidate.deleteUploadPo({
        id: file.id
      })
      if (code == 200) {
        this.$message({
          type: 'success',
          message: msg
        })
        this.getuploadList()
      } else {
        this.$message({
          type: 'info',
          message: msg
        })
      }
    },
    onChangeFile(file, fileList, type) {
      console.log(file)
      if (file.name.endsWith('.jpg') || file.name.endsWith('.pdf')) {
        this.upload(file)
      } else {
        this.$message.warning(this.$t('dc:上传文件类型错误，仅支持.pdf和jpg'))
      }
    },
    async upload(file) {
      const formData = new FormData()
      formData.append('file', file.raw)
      formData.append('id', 1)
      formData.append('type', this.type)
      // console.log('-------',formData.getAll('file'));
      let loadingInstance = this.$loading({
        lock: true,
        text: '上传中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const { code, data, msg } = await this.$Api.candidate.uploadPo(formData)
      loadingInstance.close()
      if (code == 200) {
        this.$message({
          type: 'success',
          message: msg
        })
        this.getuploadList()
      } else {
        this.$message({
          type: 'info',
          message: msg
        })
      }
    },

    // 关闭侧边栏
    cancle() {
      if (this.addFlag) {
        this.selectEditMsg = {
          beginTime: '',
          email: '',
          endTime: '',
          mobile: '',
          remark: '',
          status: '',
          userCode: '',
          userName: ''
        }
        this.$emit('goback')
      }

      this.selectFlag = false // 重新选择置为false
    }
  },
  beforeDestroy() {},
  mounted() {}
}
</script>
<style lang="scss" scoped>
.scrollContainer {
  height: calc(100vh - 250px) !important ;
  overflow: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
}
.uploadItem {
  margin-top: 16px;
}
.uploadTitle {
  width: 120px;
  // border: 1px solid red;
}
.buttonBottom {
  justify-content: flex-end;
  margin-right: 150px;
  margin-top: 20px;
  .el-button--mini {
    margin-right: 50px;
  }
}
.flexRow {
  display: flex;
  flex-direction: row;
}
.AlCenter {
  align-items: center;
}
.flexBetw {
  display: flex;
  justify-content: space-between;
}
.flex {
  display: flex;
}
.flexBetw {
  display: flex;
  justify-content: space-between;
}
.el-select--mini {
  width: 100%;
}
.buttonActive {
  width: 76px;
  height: 30px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 15px;
}
.buttonRightBox {
  margin-left: 24px;
}
.pdfBox {
  width: 100%;
  height: 100vh;
}
</style>
<style lang="scss">
.upload-list-page {
  .el-upload-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .el-upload-list__item {
      width: 220px;
      height: 92px;
      margin-right: 16px;
    }
  }
  .el-card__body {
    padding: 0 10px 20px 10px !important;
    box-sizing: border-box;
  }
}
</style>
