<template>
  <div>
    <div v-show="!manageShow && !backToneFlag" class="candidate-list-page1">
      <PocCardTable class="topPocCardTable">
        <template v-slot:headerTitle>候选人管理</template>
        <template v-slot:headerGroup>
          <el-button
            class="buttonrefresh"
            :loading="queryLoading"
            @click="refresh"
          >
            <i class="el-icon-refresh-left buttonrefreshSpan"></i>
          </el-button>
          <el-button :loading="queryLoading" role="export" @click="filterQuery"
            >查询</el-button
          >

          <el-button
            :loading="backLoading"
            role="sendback"
            v-permission
            @click="sendBack"
            >发送背调</el-button
          >
          <el-button
            :loading="rejectLoading"
            role="refusedentry"
            v-permission
            @click="refusalJoin"
            >拒绝入职</el-button
          >
          <el-button
            :loading="opsBatchLoading"
            role="refusedentry"
            v-permission
            @click="transferOps"
            >转入OPS</el-button
          >
        </template>
        <PocSlider minheight="60px" maxHeight="350px" btntype="primary">
          <el-form
            :inline="true"
            :model="formInline"
            label-position="top"
            class="el-form elformInputBox minWidthInput"
          >
             
            <el-form-item label="Chinese Name">
              <el-input
                placeholder="请输入Chinese Name"
                v-model="formInline.chineseName"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="Assigment/Project (Level2)"
              >                               
              <el-select
                clearable
                v-model="formInline.assigmentProjectLevel2"
                filterable
                placeholder="请选择"
                class="industry-selector"
              >
                                     
                <el-option
                  v-for="item in assigmentProjectLevel2List"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
                                   
              </el-select>
                               
            </el-form-item>
             <el-form-item label="Description（Level3）">
              <el-select
                clearable
                v-model="formInline.descriptionLevel3"
                filterable
                placeholder="请选择"
                class="industry-selector"
              >
                <el-option
                  v-for="item in descriptionLevel3List"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Creation User">
              <el-input
                placeholder="请输入Creation User"
                v-model="formInline.creationUser"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="English Name">
              <el-input
                placeholder="请输入English Name"
                v-model="formInline.englishName"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="Country"
              > 
              <el-select
                clearable
                v-model="formInline.country"
                filterable
                placeholder="请选择"
                class="industry-selector"
              >
                <el-option
                  v-for="item in countryList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Offer Status"
              >                                 
              <el-select
                clearable
                :disabled="statusDisableFlag"
                v-model="formInline.offerStatus"
                filterable
                placeholder="请选择"
                class="industry-selector"
              >
                                       
                <el-option
                  v-for="item in OfferStatusList"
                  :key="item.label"
                  :label="item.value"
                  :value="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
              <el-form-item label="是否转入OPS">
                <el-select
                  clearable
                  v-model="formInline.intoOps"
                  filterable
                  placeholder="请选择"
                  class="industry-selector"
                >
                  <el-option
                    v-for="item in [{k:'是',v:true},{k:'否',v:false}]"
                    :key="item.k"
                    :label="item.k"
                    :value="item.v"
                  ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Offer Date">
              <el-date-picker
                style="width: 100%"
                v-model="dateRange"
                type="daterange"
                align="right"
                unlink-panels
                :clearable="true"
                range-separator="-"
                start-placeholder="Start Date"
                end-placeholder="End Date"
                value-format="yyyy-MM-dd"
                @blur="onFilter('daterange', dateRange)"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="Onboarding Date">
              <el-date-picker
                style="width: 100%"
                v-model="dateRange2"
                type="daterange"
                align="right"
                unlink-panels
                clearable
                range-separator="-"
                start-placeholder="Start Date"
                end-placeholder="End Date"
                value-format="yyyy-MM-dd"
                @blur="onFilter2('daterange2', dateRange2)"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="TA Owner">
              <el-select
                clearable
                v-model="formInline.taInHandId"
                filterable
                placeholder="请选择"
                class="industry-selector"
              >
                <el-option
                  v-for="item in taOwnerList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </PocSlider>
      </PocCardTable>
      <PocCardTable>
        <div class="flex opeartionBox">
          <el-radio-group @change="changeTab" v-model="chooseTab">
            <el-radio :label="i" v-for="i in [1, 3, 4, 5]" :key="'key' + i">{{
              tabLabel[i]
            }}</el-radio>
          </el-radio-group>
          <!-- 待确定是否要加权限 -->
          <el-button type="primary" @click="downloadCandidateList"
            >下载全部 Candidate List</el-button
          >
        </div>
        <PocTable
          v-loading="queryLoading"
          :data="tableData"
          size="large"
          stripe
          :current-page.sync="pageConfig.pageNum"
          :total="pageConfig.total"
          @size-change="pageSizeChange"
          @page-current-change="pageChange"
          :max-height="1000000000"
          @selection-change="handleSelectionChange"
          @row-click="goDetail"
          ref="multipleTable"
          row-key="offerProposalId"
        >
          <el-table-column
            type="selection"
            width="45"
            :reserve-selection="true"
          >
          </el-table-column>
          <el-table-column
            v-for="item in columnOptions"
            :key="item.key"
            :prop="item.key"
            :label="item.name"
            :fixed="item.fixed"
            :sortable="item.sortable"
            :min-width="item.width"
            align="left"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span
                v-if="item.key == 'backTone'"
                class="seeOrg"
                @click.stop="seeBackMsg(scope.row)"
              >
                <span style="color: green">{{ scope.row.green || 0 }}</span> /
                <span style="color: red">{{ scope.row.red || 0}}</span>
              </span>
              <span v-else-if="item.key == 'offerStatus'" class="seeOrg">
                <!-- <div></div> -->
                <div
                  v-if="scope.row[item.key] == null"
                  class="circleColor circleYellow"
                ></div>
                <div
                  v-if="scope.row[item.key] == 'offer未提交'"
                  class="circleColor circleYellow"
                ></div>
                <div
                  v-else-if="scope.row[item.key] == 'offer已提交审批'"
                  class="circleColor circleGreen1"
                ></div>
                <div
                  v-else-if="scope.row[item.key] == 'BP审批驳回'"
                  class="circleColor circleRed"
                ></div>
                <div
                  v-else-if="scope.row[item.key] == 'BP审批通过'"
                  class="circleColor circleGreen2"
                ></div>
                <div
                  v-else-if="scope.row[item.key] == 'Finance审批驳回'"
                  class="circleColor circleRed"
                ></div>
                <div
                  v-else-if="scope.row[item.key] == 'Finance审批通过'"
                  class="circleColor circleGreen2"
                ></div>
                <div
                  v-else-if="scope.row[item.key] == 'offer已发送'"
                  class="circleColor circleBlue1"
                ></div>
                <div
                  v-else-if="scope.row[item.key] == 'offer已拒绝'"
                  class="circleColor circleRed"
                ></div>
                <div
                  v-else-if="scope.row[item.key] == 'offer已签回'"
                  class="circleColor circleBlue2 offerInfo"
                  @click="openOfferInfo(scope.row)"
                ></div>
                <div
                  v-else-if="scope.row[item.key] == 'turn down'"
                  class="circleColor circleGrey"
                ></div>
                <span v-if="scope.row[item.key] == 'offer已签回'" class="offerInfo" @click.stop="openOfferInfo(scope.row)">{{ scope.row[item.key] }}</span>
                <span v-else>{{ scope.row[item.key] }}</span>
              </span>
              <span v-else class="orgName" @click="goDetail(scope.row)">{{
                scope.row[item.key]
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Operations"
            width="100"
            align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-row
                :gutter="24"
                style="display: flex; justify-content: center"
              >
                <el-col :span="8" role="downloadBD" v-permission>
                  <el-tooltip content="下载背调" placement="top">
                    <img
                      class="iconImg pointer"
                      src="@/assets/img/download.png"
                      @click.stop="downloadBd(scope.row)"
                      alt=""
                    />
                  </el-tooltip>
                </el-col>
                <el-col :span="8" role="sendoffer" v-permission>
                  <el-tooltip content="发送offer" placement="top">
                    <img
                      class="iconImg pointer"
                      src="@/assets/img/sendOffer.png"
                      @click.stop="sendOffer(scope.row)"
                      alt=""
                    />
                    <!-- <el-button
                      class="buttonUnactive"
                      :loading="sendLoading"
                      role="sendoffer"
                      v-permission
                      @click.stop="sendOffer(scope.row)"
                      >发送offer</el-button
                    > -->
                  </el-tooltip>
                </el-col>
                <el-col :span="8"  role="ops" v-permission>
                  <el-tooltip content="转入ops" placement="top">
                    <img
                      class="iconImg pointer"

                      src="@/assets/img/transferOps.png"
                      @click.stop="returnOPs(scope.row)"
                      alt=""
                    />
                    <!-- <el-button
                      type="primary"
                      class="buttonUnactive"
                      @click.stop="returnOPs(scope.row)"
                      role="ops"
                      v-permission
                      >转入ops</el-button
                    > -->
                  </el-tooltip>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
        </PocTable>
      </PocCardTable>
    </div>
    <edit-detail-parent-manage
      v-if="manageShow"
      :jumpParams="jumpParams"
      @goback="goback"
    />
    <!-- 背调页面组件 -->
    <back-msg v-if="backToneFlag" :jumpParams="jumpParams" @goback2="goback2" />
    <el-dialog
      :title="dailogTitle"
      :visible.sync="dialogVisible"
      width="50%"
      class="PersonnelSelectionDialog"
      append-to-body
      destroy-on-close
      :before-close="close"
    >
      <div class="PersonnelSelectionDialog__title">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item :label="dailogContent">
            <el-select v-model="offerType" clearable placeholder="">
              <el-option
                v-for="item in offerDemoTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="submitOffer">确 定</el-button>
      </span>
    </el-dialog>
    <TurnDownOfferDialog ref="turnDownOfferDialogRef" @commit="rejectReasonCommit" />
  </div>
</template>

<script>
import { exportCandidateList } from '@/api/employees'
import { getTheyearOptions, stampToTime } from '@/utils/util'
const { Vuex } = $PCommon
const { mapGetters } = Vuex

import editDetailParentManage from './editDetailParentManage.vue'
import backMsg from './backMsg.vue'
import backInput from './backInput.vue'
import PocSlider from '@/components/poc-slider/poc-slider'
import { getTaUserList } from "@/api/organization";
import TurnDownOfferDialog from "@/views/turnDownOfferRecord/turnDownOfferDialog.vue";
export default {
  name: 'userManage',
  mixins: [$PCommon.TableMixin],
  components: {
    TurnDownOfferDialog,
    editDetailParentManage,
    backMsg,
    backInput,
    PocSlider
  },
  watch: {
    dateRange(newValue, oldValue) {
      this.formInline.offerDateStart = ''
      this.formInline.offerDateEnd = ''
    },
    dateRange2(newValue, oldValue) {
      this.formInline.onBoardingDateStart = ''
      this.formInline.onBoardingDateEnd = ''
    }
  },

  data() {
    return {
      maxHeight: '',
      queryLoading: false,
      backLoading: false,
      sendLoading: false,
      rejectLoading: false,
      opsBatchLoading: false,
      opsLoading: false,
      dateRange: '',
      dateRange2: '',
      dailogTitle: '',
      tableData: [],
      upFlag: false,
      backToneFlag: false, //背调组件默认false不显示
      inputBackFlag: false,
      dialogVisible: false,
      tabLabel: {
        1: 'ALL',
        // 2: '待提交',
        3: '已审批offer',
        4: '已发送offer',
        5: '待入职'
      },
      typeData: {
        1: 'ALL',
        // 2: 'unSubmit',
        3: 'approvedOffer',
        4: 'submitOffer',
        5: 'pending'
      },
      chooseTab: 1,
      columnConfig: [
        {
          key: 'englishName',
          name: 'English Name',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 120 // 默认宽度，像素
        },
        {
          key: 'chineseName',
          name: 'Chinese Name',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 120 // 默认宽度，像素
        },
        {
          key: 'onBoardingDate',
          name: 'Onboarding Date',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 130 // 默认宽度，像素
        },
        {
          key: 'assigmentProjectLevel2',
          name: 'Assigment/Project (Level2)',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 200 // 默认宽度，像素
        },
        {
          key: 'offerStatus',
          name: 'Offer Status',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 100 // 默认宽度，像素
        },
        {
          key: 'taOwner',
          name: 'TA Name',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 100 // 默认宽度，像素
        },
        {
          key: 'backTone',
          name: 'Reference Check',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 130 // 默认宽度，像素
        },
        {
          key: 'backToneStatus',
          name: 'Reference Status',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 140 // 默认宽度，像素
        },

        // {
        //   key: 'gender',
        //   name: 'Gender',
        //   dataType: 'string',
        //   visible: true,
        //   sortable: false,
        //   fixed: false, // 固定列的位置(left, right)
        //   width: false // 默认宽度，像素
        // },
        // {
        //   key: 'countryName',
        //   name: 'Country',
        //   dataType: 'string',
        //   visible: true,
        //   sortable: false,
        //   fixed: false, // 固定列的位置(left, right)
        //   width: 120 // 默认宽度，像素
        // },
        // {
        //   key: 'nation',
        //   name: 'Nation',
        //   dataType: 'string',
        //   visible: true,
        //   sortable: false,
        //   fixed: false, // 固定列的位置(left, right)
        //   width: false // 默认宽度，像素
        // },
        {
          key: 'offerDate',
          name: 'Offer Date',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 110 // 默认宽度，像素
        },
        {
          key: 'opsStatus',
          name: '是否转入OPS',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 130 // 默认宽度，像素
        }
      ],
      formInline: {
        assigmentProjectLevel2: '',
        taInHandId:'',
        englishName: '',
        chineseName: '',
        intoOps: '',
        gender: '',
        country: '',
        nation: '',
        offerStatus: '',
        offerDateStart: '',
        offerDateEnd: '',
        onBoardingDateStart: '',
        onBoardingDateEnd: '',
        descriptionLevel3: '',
        creationUser: '',
        type: 'all'
      },
      statusDisableFlag: false, //默认进来可以选择。all可以选择，其他不可以选择
      selectofferProposalIds: [], // 选中数组id集合字符串
      selectofferProposalIdsArr: [],
      cannotSubmitNameArr: [],
      hiringIds: [],
      canSubmitEmailArr: [],
      statusList: [
        { id: '0', name: '启用' },
        { id: '1', name: '停用' }
      ],
      OfferStatusList: [],
      nationList: [],
      countryList: [],
      // brandNameList: [],
      assigmentProjectLevel2List: [],
      descriptionLevel3List: [],
      genderList: [
        { id: '1', name: '男' },
        { id: '2', name: '女' }
      ],
      props: { multiple: true },
      manageShow: false,
      jumpParams: {},
      selectedItems: [],
      offerDemoTypeList: [
        { id: '1', name: 'PG China Offer Letter Template - Local - General' },
        {
          id: '2',
          name: 'PG China Offer Letter Template - Local - Target Bonus'
        },
        {
          id: '3',
          name: 'PG China Offer Letter Template - Local - No probation'
        },
        { id: '4', name: 'PG China Offer Letter Template - Expat  - General' },
        {
          id: '5',
          name: 'PG China Offer Letter Template- Expat - General -Target Bonus'
        },
        {
          id: '6',
          name: 'PG China Offer Letter Template - Expat - No probation'
        },
      ],
      offerType: '',
      dailogContent: '',
      selectdofferProposalId: '',
      taOwnerList:[],
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    theyearOptions() {
      // 获取年份列表
      return getTheyearOptions()
    },
    columnOptions() {
      return this.columnConfig.filter((item) => item.visible)
    },
    ...mapGetters(['userInfo'])
  },

  async created() {
    let h =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    this.maxHeight = h - 370
    this.getTaOwnerList()
    await this.filterQuery()
    await this.getCountry()
    // await this.getBrandName();
    await this.getAssigmentProjectLevel2()
    await this.getDescriptionLevel3()
    // await this.getNationList()
    await this.getOfferStatusList()
  },
  methods: {
    openOfferInfo(row){
      if(row.offerUrl){
        window.open(row.offerUrl)
      }
    },
    //下载全部Staff List
    downloadCandidateList() {
      exportCandidateList()
    },
    async getTaOwnerList() {
      // getTaUserList()
      //   .then(({code,data})=>{
      //     console.log(data);
      //     this.taOwnerList = data
      //   })

      const { code, data } = await this.$Api.induction.getHandList({})
      if (code === 200) {
        this.taOwnerList = data
      }
    },
    onFilter(type, value) {
      this.formInline.offerDateStart = value[0]
      this.formInline.offerDateEnd = value[1]
    },
    onFilter2(type, value) {
      this.formInline.onBoardingDateStart = value[0]
      this.formInline.onBoardingDateEnd = value[1]
    },
    refresh() {
      ;(this.formInline = {
        assigmentProjectLevel2: '',
        englishName: '',
        chineseName: '',
        gender: '',
        country: '',
        nation: '',
        offerStatus: '',
        offerDateStart: '',
        offerDateEnd: '',
        onBoardingDateStart: '',
        onBoardingDateEnd: '',
        descriptionLevel3: '',
        creationUser: '',
        type: 'all'
      }),
        this.filterQuery()
    },
    // 下载背调
    downloadBd(row){
      this.$confirm(`是否下载 ${row.englishName} 的背调报告？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      }).then(() => {
        this.$Api.candidate.downloadBackInfo({id:row.offerProposalId})
      }).catch(()=>{
        return
      })
    },
    upRow() {
      this.upFlag = !this.upFlag
    },
    goback() {
      this.manageShow = false
      this.filterQuery()
    },
    goback2() {
      this.backToneFlag = false
      this.filterQuery()
    },

    close() {
      this.dialogVisible = false
    },
    changeTab(activeTab) {
      this.chooseTab = activeTab
      if (this.chooseTab != '1') {
        this.statusDisableFlag = true
        this.formInline.offerStatus = ''
      } else {
        this.statusDisableFlag = false
      }
      //这里的ALL，不是全选功能。而是tab切换
      this.formInline.type = this.typeData[activeTab]
      this.filterQuery()
    },
    seeBackMsg(row) {
      //查看背调信息，调出背调信息的组件页面
      if((!row.green && !row.red) || (row.green === 0 && row.red === 0)){
        this.$message.info("该员工没有背调信息")
        return
      }

      this.backToneFlag = true
      this.jumpParams = { offerProposalId: row.offerProposalId }
      // //待删除
      // this.inputBackFlag=true;
      console.log(row.id, this.backToneFlag)
    },
    async getCountry() {
      const { data, code, msg } = await this.$Api.induction.getCountry({})
      if (code === 200) {
        this.countryList = data
      } else {
        this.$message({
          type: 'error',
          message: msg
        })
      }
    },
    // async getBrandName() {
    //   const { code, data } = await this.$Api.candidate.getBrandName({
    //     bussinessUnitId: ''
    //   })
    //   if (code === 200) {
    //     this.brandNameList = data
    //   }
    // },
    async getAssigmentProjectLevel2() {
      const { code, data } =
        await this.$Api.induction.getAssigmentProjectLevel2({})
      if (code === 200) {
        this.assigmentProjectLevel2List = data
      }
    },
    async getDescriptionLevel3() {
      const { code, data } = await this.$Api.induction.getDescriptionLevel3({})
      if (code === 200) {
        this.descriptionLevel3List = data
      }
    },
    filterQuery() {
      this.pageConfig.pageNum = 1
      this.queryApi()
    },
    // 获取基础数据维护的列表
    async queryApi() {
      this.queryLoading = true
      const { data, code } = await this.$Api.candidate.getDataList({
        ...this.formInline,
        ...this.pageConfig
      })
      this.queryLoading = false
      if (code === 200) {
        this.tableData = data.list ? data.list : []
        this.pageConfig.total = data.total
        this.$forceUpdate()
      }
    },
    handleSelectionChange(val) {
      console.log('val', val)
      // 选的第一个
      if (val.length) {
        this.selectedItems = val
        this.selectofferProposalIds = this.selectedItems
          .map((v) => v.offerProposalId)
          .join()
        this.selectofferProposalIdsArr = this.selectedItems.map(
          (v) => v.offerProposalId
        )
        this.hiringIds = this.selectedItems.map((v) => v.hiringId).join()
      } else {
        this.selectofferProposalIds = ''
        this.hiringIds = ''
        this.selectedItems = []
      }
      console.log('909090', this.selectofferProposalIdsArr)
    },
    //拒绝入职原因选择弹框
    async rejectReasonCommit({reasonId,remark}) {
      let msg = '拒绝入职成功'
      this.rejectLoading = true
      const { code, data } = await this.$Api.candidate.rejectJoin({
        ids: this.selectofferProposalIdsArr,
        reasonId:reasonId,
        remark:remark
      })
      this.rejectLoading = false
      this.$refs.turnDownOfferDialogRef.queryLoading = false;
      if (code === 200) {
        this.$message({
          type: 'success',
          message: msg
        })
        this.cannotSubmitNameArr = [] //清空不可提交的数据
        this.$refs.multipleTable.clearSelection()
        this.selectofferProposalIdsArr = [] //清空选中数据
        this.$refs.turnDownOfferDialogRef.close()
        await this.queryApi() // 刷新下界面
      }
    },
    // 拒绝入职接口
   async refusalJoin() {
     console.log(this.selectedItems)
     const nowDate = Date.parse(new Date())
     this.cannotSubmitNameArr = [];
     if (!this.selectofferProposalIdsArr.length) {
       this.$message({
         type: 'success',
         message: '请选择数据'
       })
       return
     }
     // /* 注释掉时间判断，该判断放在后端 */
     // this.selectedItems.map((d) => {
     //   console.log(d.onBoardingDate)
     //   if (d.opsStatus === "是" && nowDate >= Date.parse(d.onBoardingDate + ' 18:00')) {
     //     //入职之前可以操作
     //     this.cannotSubmitNameArr.push(d.chineseName)
     //   }
     // })
     // if (this.cannotSubmitNameArr.length) {
     //   this.$message({
     //     type: 'success',
     //     message: this.cannotSubmitNameArr.join(',') + '不可拒绝入职，时间已过'
     //   })
     //   this.cannotSubmitNameArr = []
     //   return
     // }
     let res = await this.$Api.candidate.checkRejectJoin(this.selectedItems.map(a=>a.offerProposalId));
     if(res && res.code === 200){
       this.$refs.turnDownOfferDialogRef.show()
     }
   },
    // 转入ops 多
    async transferOps() {
      if (!this.selectofferProposalIdsArr.length) {
        this.$message({
          type: 'success',
          message: '请选择数据'
        })
        return
      }
      // 注释代码原准备实现：不能转的直接过滤掉不传给后端，后给提示那些不能转
      // let notArr = [];
      // let canArr = [];
      // let canIdArr = [];
      // this.selectedItems.map(item =>{
      //   if(item.offerStatusNumber != '5' || item.opsStatus == '1'){
      //     notArr.push(item.chineseName)
      //   }else{
      //     canArr.push(item.chineseName);
      //     canIdArr.push(item.hiringId);
      //   }
      // })
      // 过滤后没有空了 给提示没有可转入的
      // if (!canIdArr.length) {
      //   this.$message({
      //     type: 'success',
      //     message: '没有可转入的数据'
      //   })
      //   return
      // }
      this.opsBatchLoading = true
      const { code, data } = await this.$Api.candidate.returnOPs({
        ids: this.selectofferProposalIdsArr.join(',')
      })
      this.opsBatchLoading = false
      console.log('code', code)
      if (code === 200) {
        // let title = '';
        // if(canArr.length){
        //   title ='转入成功,' + notArr.join(',') + '不可转入ops';
        // }else{
        //   title = '全部转入成功';
        // }
        // this.$message({
        //   type: 'success',
        //   message: title
        // })
        this.$message({
          type: 'success',
          message: '转入ops成功'
        })
        this.queryApi() // 刷新下界面
      }
    },
    //转入ops 单个
    async returnOPs(row) {
      if(this.opsLoading) return;
      if (row.offerStatusNumber != '5' || row.opsStatus == '是') {
        this.$message({
          type: 'success',
          message: row.chineseName + '不可转入ops'
        })
        return
      }
      this.opsLoading = true;
      const { code, data } = await this.$Api.candidate.returnOPs({
        ids: row.offerProposalId
      })
      this.opsLoading = false;
      if (code === 200) {
        this.$message({
          type: 'success',
          message: '转入ops成功'
        })
        this.queryApi() // 刷新下界面
      }
    },
    //背调结果录入
    async backEntry(row) {
      if (
        row.offerStatusNumbe != '8' ||
        row.offerStatusNumbe != '9' ||
        row.offerStatusNumbe != '0'
      ) {
        this.$message({
          type: 'success',
          message: row.chineseName + '不可背调录入'
        })
        return
      }
      this.selectdofferProposalId = row.offerProposalId
      this.dailogTitle = '背调录入'
      this.dailogContent = '请选择背调类型'
      ;(this.offerDemoTypeList = [
        { id: '1', name: '背调通过' },
        { id: '2', name: '背调不通过' }
      ]),
        (this.dialogVisible = true)
    },

    // ==5 &&opsStatus!=2

    //发送背调
    async sendBack() {
      console.log(this.selectofferProposalIdsArr.length, this.selectedItems, this.cannotSubmitNameArr)
      if (!this.selectofferProposalIdsArr.length) {
        this.$message({
          type: 'success',
          message: '请选择数据'
        })
        return
      }
      this.cannotSubmitNameArr = [];
      this.selectedItems.map((d) => {
        if (d.offerStatusNumbe == '5') {
          // offerStatusNumber = 5
          this.cannotSubmitNameArr.push(d.chineseName)
        }
      })
      if (this.cannotSubmitNameArr.length) {
        this.$message({
          type: 'success',
          message: this.cannotSubmitNameArr.join(',') + '不可背调'
        })
        this.dailogTitle = ''
        this.cannotSubmitNameArr = []
        this.canSubmitEmailArr = [] //清空可提交的email
        return
      }
      let msg = '发送背调成功'
      console.log('797910', this.selectofferProposalIdsArr)
      this.backLoading = true
      const { code, data } = await this.$Api.candidate.sendBack({
        ids: this.selectofferProposalIdsArr
      })
      this.backLoading = false
      console.log(code)
      if (code === 200) {
        this.$message({
          type: 'success',
          message: msg
        })
        this.cannotSubmitNameArr = [] //清空不可提交的数据
        this.$refs.multipleTable.clearSelection()
        this.selectofferProposalIdsArr = [] //清空选中数据

        this.queryApi() // 刷新下界面
      }
    },
    //发送offer
    async sendOffer(row) {
      this.selectdofferProposalId = row.offerProposalId
      if (
        (row.offerStatusNumber != '2' && row.offerStatusNumber != '3' && row.offerStatusNumber != '4') ||
        !row.candidateEmail
      ) {
        this.$message({
          type: 'warning',
          message: row.chineseName + '不可发送'
        })
        this.dailogTitle = ''
        return
      }
      this.dailogTitle = 'offer模板'
      this.dailogContent = '请选择offer模板'
      this.dialogVisible = true
    },
    async submitOffer() {
      if (this.dailogTitle == 'offer模板') {
        if (!this.offerType) {
          this.$message({
            type: 'info',
            message: '未选择Offer模板'
          })
          return
        }
        const { code, data, msg } = await this.$Api.candidate.sendOffer({
          id: this.selectdofferProposalId,
          templateName: this.offerType
        })
        if (code === 200) {
          this.$message({
            type: 'success',
            message: '发送offer成功'
          })
          this.chooseTab == '3'
          this.selectdofferProposalId = ''
          this.offerType = ''
          this.$refs.multipleTable.clearSelection()
          this.close()
          this.queryApi()
        }
      } else {
        if (!this.offerType) {
          this.$message({
            type: 'info',
            message: '未选择背调模板'
          })
          return
        }
        console.log('------', this.selectdofferProposalId)
        const { code, data } = await this.$Api.candidate.backEntry({
          id: this.selectdofferProposalId,
          templateName: this.offerType
        })
        if (code === 200) {
          this.$message({
            type: 'success',
            message: '背调结果录入成功'
          })
          this.chooseTab == '5'
          this.dialogVisible = false
          this.offerType = ''
          this.selectdofferProposalId = ''
          this.$refs.multipleTable.clearSelection()

          this.queryApi() // 刷新下界面
        }
      }
    },
    goDetail(row) {
      this.manageShow = true
      this.jumpParams = {
        jumpFlag: 'goDetail',
        candidateId: row.candidateId,
        hiringId: row.hiringId,
        offerProposalId: row.offerProposalId
      }
      this.$refs.multipleTable.clearSelection();
      this.selectedItems = [];
    },
    // 编辑信息
    editdRow(row) {
      this.manageShow = true
      this.jumpParams = {
        jumpFlag: 'edit',
        candidateId: row.candidateId,
        hiringId: row.hiringId,
        offerProposalId: row.offerProposalId,
        buttonActive: '3'
      }
    },

    async getOfferStatusList() {
      const { code, data } = await this.$Api.candidate.getOfferStatusList({})
      if (code === 200) {
        this.OfferStatusList = data
      }
    },
    async getNationList() {
      const { code, data } = await this.$Api.candidate.getNationList({})
      if (code === 200) {
        this.nationList = data
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {})
    window.removeEventListener('keyup', () => {})
  },
  mounted() {
    window.addEventListener('keyup', (e) => {
      if ((e.code == 'Enter' || e.target == 13) && e.target.id == 'inputid') {
        console.log(e)
        this.filterQuery()
      }
    })
    window.addEventListener('resize', () => {
      const h =
        document.getElementsByTagName('body')[0].clientHeight - 340 + 'px'
      this.maxHeight = h
    })
  }
}
</script>
<style lang="scss" scoped>
.iconImg {
  width: 16px;
  height: 16px;
}
.opeartionBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0 16px 0px;
  box-sizing: border-box;
}
.circleColor {
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-right: 5px;
  background: rgb(36, 144, 227);
}
.circleRed {
  background: #de3934;
}
.circleBlue1 {
  background: #2490e3;
  opacity: 0.3;
}
.circleBlue2 {
  background: #2490e3;
}
.offerInfo:hover{
  cursor: pointer;
  text-decoration: underline;
  color: dodgerblue;
}
.circleGreen1 {
  background: #6ebaaf;
  opacity: 0.3;
}
.circleGreen2 {
  background: #6ebaaf;
}
.circleYellow {
  background: #e6a23c;
}
.circleGrey {
  background: #909399;
}
.pointer{
  cursor: pointer;
}
</style>
<style lang="scss">
.candidate-list-page1 {
  .elformInputBox {
    .el-input,
    .el-select {
      width: 166px;
    }
  }
  .poc-slider .poc-slider-btns .el-button {
    margin-right: 0 !important;
  }
}
</style>
