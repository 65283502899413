export const columnConfig = [
  {
    key: 'periodStart',
    name: 'Period Start',
    nameZn: '工作起始',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: 'sortable', // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: null // 默认宽度，像素
  },
  {
    key: 'periodEnd',
    name: 'Period End',
    nameZn: '工作截止',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: null,
    width: null
  },
  {
    key: 'companyName',
    name: 'Company',
    nameZn: '公司名称',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: null,
    width: null
  },
  {
    key: 'lastJobTitle',
    name: 'Job Title',
    nameZn: '离职前职位',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: null,
    width: 100
  },
  {
    key: 'lineManagerName',
    name: 'Line Manager',
    nameZn: '直级领导',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: null,
    width: null
  },
  {
    key: 'lineManagerPhone',
    name: 'Line Manager Phone',
    nameZn: '直级领导电话',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: null,
    width: null
  }
]
