<template>
  <div class="editInduction-list-page">
    <!-- <PocCardTable class="irisePocCardTable detailPageContainer"> -->
    <PocCardTable class="irisePocCardTable detailPageContainer">
      <template v-slot:headerTitle>
        <span style="font-weight: 400">候选人管理</span>
      </template>
      <template v-slot:headerGroup>
        <el-button @click="goback">取 消</el-button>
      </template>
      <div class="editContainer">
        <template>
          <div class="flexRow">
            <!-- 3,4 -->
            <div
              class="scopeItem"
              :class="buttonActive == i ? 'active' : 'unactive'"
              v-for="i in [1, 2, 'Employment']"
              :key="'key' + i"
              @click="changeTab(i)"
            >
              {{ tabLabel[i] }}
              <div
                style="font-size: 0"
                class="guidaoactive"
                v-if="buttonActive == i"
              >
                <img
                  class="guidaoactiveImg"
                  src="@/assets/img/guidaoactive.png"
                  alt=""
                />
              </div>
            </div>
            <!-- <el-button type="primary" :class="buttonActive==i?'buttonActive':'buttonunActive'"  v-for="i in [1, 2,]"
          :key="'key' + i"
          @click="changeButton(i)">{{ buttonData[i] }}</el-button> -->
          </div>
          <div style="font-size: 0" class="guidao">
            <img class="guidaoImg" src="@/assets/img/guidao.png" alt="" />
          </div>
          <!-- 详情的区域 -->
          <div v-if="jumpParams.jumpFlag == 'goDetail'">
            <!-- 上传区域 -->
            <detail-msg-manage
              v-if="buttonActive != 4"
              :buttonActive="buttonActive"
              :jumpParams="jumpParams"
              @goback="goback"
            />
            <upload-manage v-else :jumpParams="jumpParams" />
          </div>
          <EmploymentHistory
            v-else-if="jumpParams.jumpFlag === 'EmploymentHistory'"
            :jumpParams="jumpParams"
            @goback="goback"
          />
          <!-- 编辑区域 -->
          <edit-manage v-else :jumpParams="jumpParams" @goback="goback" />
        </template>
      </div>
    </PocCardTable>
  </div>
</template>

<script>
const { Vuex } = $PCommon
import detailMsgManage from './detailMsgManage.vue'
import editManage from './editManage.vue'
import uploadManage from './uploadManage.vue'
import EmploymentHistory from '@/views/inductionManage/components/EmploymentHistory'

export default {
  name: 'userManage',
  mixins: [$PCommon.TableMixin],
  components: {
    detailMsgManage,
    editManage,
    uploadManage,
    EmploymentHistory
  },

  props: {
    jumpParams: {
      type: Object
    }
  },
  //  3: '编辑',
  //         4: '上传附件'
  data() {
    return {
      buttonActive: '1',
      buttonData: {
        1: 'Hiring Brief Info',
        2: 'Offer Proposal'
      },
      tabLabel: {
        1: 'Hiring Brief Info',
        2: 'Offer Proposal',
        Employment: 'Employment History'
      },
      chooseTab: 1,
      props: { multiple: true }
    }
  },
  computed: {},

  created() {
    if (this.jumpParams.jumpFlag == 'edit') {
      this.buttonActive = '3' //处理编辑进来的按钮为第3个
    } else {
      this.buttonActive = '1' //详情默认第一个按钮
    }
  },
  methods: {
    goback() {
      this.$emit('goback')
    },
    changeTab(activeTab) {
      this.buttonActive = activeTab
      if (this.buttonActive == 3) {
        this.jumpParams.jumpFlag = 'edit'
      } else if (this.buttonActive === 'Employment') {
        this.jumpParams.jumpFlag = 'EmploymentHistory'
      } else {
        this.jumpParams.jumpFlag = 'goDetail'
      }
    }
    // changeButton(activeTab) {
    //   this.buttonActive = activeTab;
    //   if(this.buttonActive==3){
    //     this.jumpParams.jumpFlag='edit';
    //   }else{
    //     this.jumpParams.jumpFlag='goDetail';
    //   }
    // },
  },
  beforeDestroy() {},
  mounted() {}
}
</script>
<style lang="scss" scoped>
.editContainer {
  width: 100%;
  padding: 0px 10px 0 10px;
  box-sizing: border-box;
}
.guidaoactiveImg {
  width: 100%;
  height: 2px;
}
.flexRow {
  display: flex;
  flex-direction: row;
}
.flexCenter {
  justify-content: center;
  align-items: center;
}
.AlCenter {
  align-items: center;
}
.flexBetw {
  display: flex;
  justify-content: space-between;
}
.upContainer {
  width: 90px;
  margin-top: 7px;
  justify-content: flex-end;
}
.bottomButton {
  width: 180px;
  padding: 20px;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  right: 0;
}
.bottomBox {
  width: 180px;
  padding: 20px;
  box-sizing: border-box;
  z-index: 99;
  position: fixed;
  bottom: 0;
  right: 20px;
}
.flex {
  display: flex;
}
.flexBetw {
  display: flex;
  justify-content: space-between;
}
.el-range-editor {
  width: 100%;
}
.buttonActive {
  width: 132px;
  height: 30px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 15px;
}
.buttonunActive1 {
  width: 68px;
  height: 30px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 15px;
}
.buttonunActive {
  width: 132px;
  height: 30px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ba9765;
  border-radius: 4px;
  color: #ba9765;
  font-size: 14px;
  line-height: 15px;
}
.lableDetail {
  color: #4e4e50;
  font-size: 14px;
  font-weight: 400;
  font-family: PingFangSC-Regular, PingFang SC;
}
.lableContent {
  color: #545454;
  font-size: 14px;
  // font-weight: 300;
  margin-left: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
}
.lableContent span {
  color: #545454;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
}
.scopeItem {
  width: 151px;
  color: #828282;
  font-size: 14px;
  font-weight: 400;
  // border: 1px solid red;
  text-align: center;
  margin-top: 16px;
}
.active {
  color: #303133;
  text-align: center;
  font-weight: 600;
}
.unactive {
  color: #e9eaeb;
  text-align: center;
}
.scopeContainer {
  margin-top: 23px;
}
.guidaoactive {
  padding-top: 8px;
  box-sizing: border-box;
  width: 151px;
  overflow: hidden;
}
.guidao {
  font-size: 0;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  .guidaoImg {
    overflow: hidden;
    height: 1px;
  }
}
</style>
