<template>
  <!-- 查看详情区域 -->
  <div class="backToneContainer">
    <div class="flex opeartionBox">
      <div
        class="scopeItem"
        :class="chooseTab == item.index ? 'active' : ''"
        v-for="item in tabNameArr"
        :key="item"
        @click="changeTab(item)"
      >
        {{ item.reciteName }}({{ item.workRelation }})
        <div
          style="font-size: 0"
          class="guidaoactive"
          v-if="chooseTab == item.index"
        >
          <img src="@/assets/img/guidaoactive.png" alt="" />
        </div>
      </div>
    </div>
    <div class="cardItem">
      <div class="nameTitleBox">
        <div class="h3Title">{{ backInfoMsg.reciteName }}</div>
        <div class="backIcon" @click="backIndex" v-if="showBack">
          <i class="el-icon-arrow-left"></i>返回
        </div>
      </div>
      <div class="selectBox flexRow AlCenter flexBetw" @click="seeSelectedData">
        <div>
          <span class="backName">{{ backInfoMsg.reciteName }}</span>
          <span class="backPosition"> {{ backInfoMsg.currentPostion }}</span>
        </div>

        <div class="el-icon-caret-bottom blackIcon"></div>
      </div>
      <div v-if="SelectedFlag">
        <div class="selectedBox">
          <span class="backName">{{ backInfoMsg.reciteName }}</span>
          <span class="backPosition"> {{ backInfoMsg.currentPostion }}</span>
        </div>
      </div>
    </div>
    <div class="carditemBox">
      <div class="itemTitleBox">
        <div class="itemTitle">Interviewee Info</div>
      </div>
      <div class="backPeopleBox">
        <div class="backPeopleItem flexRow AlCenter flexBetw">
          <div class="backItemBox flexBetw AlCenter">
            <span class="backItemLable">Your Name 您的姓名:</span>
            <span class="backItemConetnt">{{ backInfoMsg.reciteName }}</span>
          </div>
          <div class="backItemBox flexBetw AlCenter">
            <span class="backItemLable"
              >Phone Number & WeChat 电话号码&微信:</span
            >
            <span class="backItemConetnt">{{ backInfoMsg.phoneNumber }}</span>
          </div>
        </div>
        <div class="backPeopleItem flexRow AlCenter flexBetw">
          <div class="backItemBox flexBetw AlCenter">
            <span class="backItemLable"
              >Current Company & Title 目前在职公司&岗位:</span
            >
            <span class="backItemConetnt">{{
              backInfoMsg.currentPostion
            }}</span>
          </div>
          <div class="backItemBox flexBetw AlCenter">
            <span class="backItemLable">Score 成绩</span>
            <span class="backItemConetnt">{{ backInfoMsg.backScore }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="carditemBox">
      <div class="itemTitleBox">
        <div class="itemTitle">Questions & Answers</div>
      </div>
      <div class="backPeopleBox">
        <div class="questionItem">
          <div class="questionTitleBox flexRow flexBetw">
            <div class="questionTitle2">
              1. What was your professional relationship to candidate?
              请问候选人与您的工作关系是？
            </div>
            <div class="questionContent2">
              <el-radio-group v-model="backInfoMsg.workRelation" disabled>
                <el-radio label="Direct Line Manager"
                  >Direct Line Manager 直属领导</el-radio
                >
                <el-radio label="HR">HR</el-radio>
                <el-radio label="Colleague">Colleague 同事</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="textareaContent">{{ backInfoMsg.workRelation }}</div>
        </div>
        <div class="questionItem">
          <div class="questionTitle">
            2. What are the start and end dates for the candidate time working
            at your company and his/her
            position?候选人服务您公司时间自何时开始到何时结束，他/她当时的职位是？
          </div>
          <div class="DateBox flexRow flexBetw">
            <div>
              <div class="questionTitle">Start Date 开始日期</div>
              <div class="dateContent">{{ backInfoMsg.companyStartDate }}</div>
            </div>
            <div>
              <div class="questionTitle">End Date 结束日期</div>
              <div class="dateContent">{{ backInfoMsg.companyEndDate }}</div>
            </div>
          </div>
          <div class="PositionTitle">Position 职位</div>
          <div class="textareaContent" style="margin-top: 10px !important">
            {{ backInfoMsg.postion }}
          </div>
        </div>
        <div class="questionItem">
          <div class="questionTitleBox flexRow flexBetw">
            <div class="questionTitle2">
              3. What can you tell me about candidate’s knowledge and
              performance in this position?
              请您描述下候选人在他/她当时岗位上的技能掌握和工作表现？
            </div>
            <div class="questionContent2">
              <el-radio-group v-model="backInfoMsg.workPerformance" disabled>
                <el-radio label="Excellent">Excellent 优秀</el-radio>
                <el-radio label="Good">Good 较好</el-radio>
                <el-radio label="Average">Average 一般</el-radio>
                <el-radio label="Below Average">Below Average 不佳</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="textareaContent">{{ backInfoMsg.workPerformance }}</div>
        </div>
        <div class="questionItem">
          <div class="questionTitleBox flexRow flexBetw">
            <div class="questionTitle2">
              4. How did he/she get along with others?
              请问候选人与公司其他员工相处得如何？
            </div>
            <div class="questionContent2">
              <el-radio-group v-model="backInfoMsg.workWithOthers" disabled>
                <el-radio label="Excellent">Excellent 优秀</el-radio>
                <el-radio label="Good">Good 较好</el-radio>
                <el-radio label="Average">Average 一般</el-radio>
                <el-radio label="Below Average">Below Average 不佳</el-radio>
                <!-- <el-radio :label="6">Not responsibility 不承担管理职责</el-radio> -->
              </el-radio-group>
            </div>
          </div>
          <div class="textareaContent">
            {{ backInfoMsg.workWithOthersComments }}
          </div>
        </div>
        <div class="questionItem">
          <div class="questionTitleBox flexRow flexBetw">
            <div class="questionTitle2">
              5.Did he/she supervise any staff? If yes, how did he/she perform?
              请问候选人是否担任管理职责？如果是的话，他/她的管理能力如何？
            </div>
            <div class="questionContent2">
              <el-radio-group v-model="backInfoMsg.manageAbility" disabled>
                <el-radio label="Excellent">Excellent 优秀</el-radio>
                <el-radio label="Good">Good 较好</el-radio>
                <el-radio label="Average">Average 一般</el-radio>
                <el-radio label="Below Average">Below Average 不佳</el-radio>
                <el-radio label="Not responsibility"
                  >Not responsibility 不承担管理职责</el-radio
                >
              </el-radio-group>
            </div>
          </div>
          <div class="textareaContent">
            {{ backInfoMsg.manageAbilityComments }}
          </div>
        </div>
        <div class="questionItem">
          <div class="flexRow flexBetw">
            <div class="questionTitle2">
              6. Did the candidate voluntary resign from the position?
              候选人是主动请辞该职位的吗？
            </div>
            <div class="questionContent2">
              <el-radio-group v-model="backInfoMsg.resignReason" disabled>
                <el-radio label="Yes">Yes 是</el-radio>
                <el-radio label="No">No 不是</el-radio>
                <el-radio label="Contract period over and did not renew">
                  Contract period over and did not renew
                  合约到期不续签</el-radio
                >
              </el-radio-group>
            </div>
          </div>
          <div class="textareaContent">{{ backInfoMsg.resignReason }}</div>
        </div>
        <div class="questionItem">
          <div class="flexRow flexBetw">
            <div class="questionTitle2">
              7. Did the candidate achieve any special award or get disciplinary
              actions? 候选人是否获得过特殊奖励或受到纪律处分?
            </div>
            <div class="questionContent2">
              <el-radio-group v-model="backInfoMsg.rewardOrPunishment" disabled>
                <el-radio label="Award">Award 奖励</el-radio>
                <el-radio label="Disciplinary">Disciplinary 纪律处分</el-radio>
                <el-radio label="No thing">Nothing 无</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="textareaContent">
            {{ backInfoMsg.rewardOrPunishmentComments }}
          </div>
        </div>

        <div class="questionItem">
          <div class="questionTitleBox flexRow flexBetw">
            <div class="questionTitle2">
              8. Would you re-hire/work with/work for the candidate again?
              Why/why not? 你会选择再次与候选人共事吗？为什么？
            </div>
            <div class="questionContent2">
              <el-radio-group v-model="backInfoMsg.workTogetherAgain" disabled>
                <el-radio label="Yes">Yes 会</el-radio>
                <el-radio label="No">No 不会</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="textareaContent">
            {{ backInfoMsg.workTogetherAgainCommnets }}
          </div>
        </div>
        <div class="questionItem">
          <div class="questionTitle">
            9. What do you think are 3 of the candidate’s outstanding qualities?
            你认为候选人的三个主要杰出品质是什么？
          </div>
          <div class="textareaContent">{{ backInfoMsg.advantages }}</div>
        </div>
        <div class="questionItem">
          <div class="questionTitle">
            10. Any weakness/area of further improvements?
            您觉得他/她有什么弱点或是尚待改进之处？
          </div>
          <div class="textareaContent">{{ backInfoMsg.weaknesses }}</div>
        </div>
        <div class="questionItem">
          <div class="questionTitle">
            11. Is there anything else we should take into consideration before
            hiring this candidate?
            在我们雇佣这位候选人之前还有什么我们应该考虑在内的？
          </div>
          <div class="textareaContent">{{ backInfoMsg.beforeHireThink }}</div>
        </div>
      </div>
    </div>
    <el-backtop target=".backToneContainer" :bottom="100">
      <div class="el-icon-arrow-up upScroll"></div
    ></el-backtop>
  </div>
</template>

<script>
import { getTheyearOptions, stampToTime } from '@/utils/util'
const { Vuex } = $PCommon
const { mapGetters } = Vuex
export default {
  name: 'userManage',
  mixins: [$PCommon.TableMixin],
  components: {},

  data() {
    return {
      SelectedFlag: false,
      radio: '1', // 默认选中已分配机构
      statusList: [
        { id: 0, name: '启用' },
        { id: 1, name: '停用' }
      ],
      backInfoMsg: [],
      backInfoMsgList: [],
      tabNameArr: [],
      tabnameObj: {},
      workTogetherAgain: '是',
      manageAbility: '',
      chooseTab: 0,
      tabLabel: {
        1: '社保公积金',
        2: '已分配机构'
      },
      showBack:true,
    }
  },
  props: {
    jumpParams: {
      type: Object
    }
  },
  computed: {},

  created() {
    let offerProposalId = this.$route.query.offerProposalId
    if(offerProposalId){
      this.getBackInfo(offerProposalId,true)
      this.showBack = false
    }else {
      console.log('-------------', this.jumpParams)
      this.getBackInfo(this.jumpParams.offerProposalId,false)
    }
  },
  methods: {
    seeSelectedData() {
      this.SelectedFlag = !this.SelectedFlag
    },
    backIndex() {
      this.$emit('goback2')
    },
    changeTab(activeTab) {
      this.chooseTab = activeTab.index
      this.backInfoMsg = this.backInfoMsgList[activeTab.index]
      console.log('activeTab', this.backInfoMsg)
    },
    async getBackInfo(id,state) {
      let res
      if(state){
        res = await this.$Api.candidate.employeeBackInfo({
          id: id
        })
      }else {
        res = await this.$Api.candidate.getBackInfo({
          id: id
        })
      }
      const { data, code, msg } = res
      if (code === 200 && data.list ) {
        const arr = []
        data.list.map((v, index) => {
          this.tabnameObj = {
            reciteName: v.reciteName ? v.reciteName : index,
            index: index,
            workRelation: v.workRelation
          }
          this.tabNameArr.push(this.tabnameObj)
          v.companyEndDate = v.companyEndDate
            ? v.companyEndDate.slice(0, 10)
            : ''
          v.companyStartDate = v.companyStartDate
            ? v.companyStartDate.slice(0, 10)
            : ''
          console.log(this.tabNameArr)
        })

        this.backInfoMsgList = data.list
        this.backInfoMsg = data.list[0]
      } else {
        this.$message({
          type: 'error',
          message: msg
        })
      }
    }
  },
  beforeDestroy() {},
  mounted() {}
}
</script>
<style lang="scss" scoped>
.backToneContainer {
  width: 100%;
  height: calc(100vh - 100px);
  overflow: auto;
  // border: 1px solid red;
  padding: 0px 180px 0 180px;
  box-sizing: border-box;
}
.cardItem {
  border-radius: 2px;
  color: rgb(122, 135, 142);
  display: block;
  //font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  text-size-adjust: 100%;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
}
.nameTitleBox {
  width: 100%;
  height: 88px;
  line-height: 88px;
  text-align: center;
  color: #fff;
  background-color: #8bc34a;
  font-weight: bold;
  position: relative;
  .h3Title {
    font-size: 24px;
    font-weight: bold;
  }
  .backIcon {
    position: absolute;
    left: 20px;
    top: -22px;
  }
}
.selectBox {
  height: 34px;
  padding: 0 20px 0 20px;
  box-sizing: border-box;
  background: #fff;
}
.backName {
  color: #4d627b;
  font-size: 13px;
}
.backPosition {
  color: #909ba1;
  font-size: 12px;
}
.blackIcon {
  font-size: 14px;
}
.blackIcon:hover {
  cursor: pointer;
}
.carditemBox {
  border: 0;
  border-radius: 3px;
  box-shadow: none !important;
  margin-bottom: 20px;
  background: #fff;
  // border: 1px solid red;
  padding: 10px 20px 45px 20px;
  box-sizing: border-box;
  // position: relative;
  // z-index: 0;
}
.itemTitleBox {
  height: 42px;
  line-height: 42px;
  // border: 1px solid red;
  margin-bottom: 15px;
  box-sizing: border-box;
  font-size: 15px;
  color: #4d627b;
}
.backPeopleBox {
  // padding: 0px 20px 25px 20px;
  // box-sizing: border-box;
  // border: 1px solid red;
}
.backPeopleItem {
  // padding-top: 10px;
  border-top: 1px solid #ddd;
}
.backPeopleItem:hover {
  background: #ebeef5;
}
.backItemBox {
  width: 50%;
  padding: 10px 10px 10px 10px;
  box-sizing: border-box;
}
.backItemLable {
  font-size: 15px;
  color: #4d627b;
  width: 30%;
}
.backItemConetnt {
  font-size: 13px;
  color: #7a878e;
  width: 20%;
}
.questionItem {
  padding-top: 10px;
  border-top: 1px solid #ddd;
  margin-bottom: 20px;
}
.questionTitleBox {
}
.questionTitleBox:hover,
.questionTitle:hover,
.questionTitle2:hover,
.questionContent2:hover,
.dateContent:hover,
.textareaContent:hover {
  background: #ebeef5;
}
.questionTitle {
  font-size: 15px;
  color: #4d627b;
  font-weight: bold;
}
.questionTitle2 {
  font-size: 15px;
  color: #4d627b;
  font-weight: bold;
  width: 60%;
}
.questionContent2 {
  width: 40%;
  padding: 0 10px 0 15px;
  box-sizing: border-box;
  overflow: hidden;
  // border: 1px solid red;
}
.dateContent {
  font-size: 13px;
  color: #7a878e;
  margin-top: 10px;
}
.PositionTitle {
  font-size: 15px;
  color: #4d627b;
  font-weight: bold;
  margin-top: 16px;
}
.textareaContent {
  width: 100%;
  min-height: 60px;
  border-radius: 3px;
  color: #555;
  font-size: 13px;
  padding: 6px 12px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  margin-top: 20px;
}
.DateBox {
  margin-top: 20px;
}

.w100 {
  width: 100%;
}
.pr24 {
  padding-right: 24px;
  box-sizing: border-box;
}

.flexRow {
  display: flex;
  flex-direction: row;
}
.flexCenter {
  justify-content: center;
  align-items: center;
}
.AlCenter {
  align-items: center;
}
.flexBetw {
  display: flex;
  justify-content: space-between;
}
.upContainer {
  width: 90px;
  margin-top: 7px;
  justify-content: flex-end;
}
.flex {
  display: flex;
}
.flexBetw {
  display: flex;
  justify-content: space-between;
}
.el-select--mini {
  width: 100%;
}
.el-range-editor {
  width: 100%;
}
.buttonActive {
  width: 132px;
  height: 30px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 15px;
}

.lableDetail {
  color: #4e4e50;
  font-size: 14px;
  font-weight: 400;
  //font-family: PingFangSC-Regular, PingFang SC;
  // border:1px solid red;
  width: 190px;
}
.lableDetail2 {
  color: #4e4e50;
  font-size: 14px;
  font-weight: 400;
  font-family: PingFangSC-Regular, PingFang SC;
  // border:1px solid red;
  width: 300px;
}
.lableDetail3 {
  color: #4e4e50;
  font-size: 14px;
  font-weight: 400;
  font-family: PingFangSC-Regular, PingFang SC;
  // border:1px solid red;
  width: 256px;
}
.lableDetail4 {
  color: #4e4e50;
  font-size: 14px;
  font-weight: 400;
  font-family: PingFangSC-Regular, PingFang SC;
  // border:1px solid red;
  width: 400px;
}
.lableContent {
  width: 180px;
  // border: 1px solid red;
  color: #545454;
  font-size: 14px;
  // font-weight: 300;
  margin-left: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lableContentnone {
  width: 100%;
}
.lableContent span {
  color: #545454;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
}
.upScroll {
  color: #fff;
}
.selectedBox {
  padding: 10px 20px 10px 20px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0px 0px 3px 3px #ddd;
  border-radius: 4px;
  width: 100%;
  position: absolute;
  z-index: 8;
}
.opeartionBox {
  padding: 23px 0 0px 0;
  box-sizing: border-box;
  // border: 1px solid red;
  margin-bottom: 20px;
}
.scopeItem {
  width: 125px;
  margin-right: 72px;
  color: #828282;
  font-size: 14px;
  font-weight: 400;
  // border: 1px solid red;
  text-align: center;
}
.active {
  color: #303133;
  text-align: center;
}

.guidaoactive {
  padding-top: 14px;
  box-sizing: border-box;
  width: 125px;
  overflow: hidden;
}
.guidao {
  font-size: 0;
  width: 100%;
  padding: 0px 0 12px 0;
  box-sizing: border-box;
  overflow: hidden;
  .guidaoImg {
    overflow: hidden;
  }
}
</style>
<style lang="scss">
.backToneContainer {
  .el-card__body {
    padding: 0 10px 20px 10px !important;
    box-sizing: border-box;
  }
  .el-backtop {
    background: #25476a;
  }
  .el-dropdown {
    width: 100%;
  }
  //此块禁用的样式处理因为为了选中的数据不再进行可选择操作、所以此番操作可以不做逻辑处理
  .el-radio__input.is-disabled.is-checked .el-radio__inner {
    background-color: #ba9765;
    border-color: #ba9765;
  }
  .el-radio__inner::after {
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background-color: #ffffff !important;
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.15s ease-in;
  }
  .el-radio__input.is-checked .el-radio__inner::after {
    transform: translate(-50%, -50%) scale(1);
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: #ba9765;
  }
}
</style>
